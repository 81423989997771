<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/fluid-mechanics/pelton-wheel-derivation'"
      :headline="'Work done and Efficiency derivation of Pelton Turbine | The Mechanical Genius'"
      :description="'Differentiation on the basis of type, head, runner, direction of flow of water through the blades, power generation, speed, discharge required, type of energy, efficiency etc.'"
      :keywords="['Work done by Pelton Turbine','Pelton Turbine', 'Fluid Mechanics', 'Mechanical Engineering']"
      :topic="'Fluid Mechanics'"
      :topiclink="'https://themechanicalgenuis.com/fluid-mechanics'"
      :question="'Work done and Efficiency derivation of Pelton Turbine'"
      :answer="'Derivation of work done and hydraulic efficiency  of Pelton Turbine with inlet and outlet velocity triangles'"
    />
    <teleport to="head">
      <component :is="'script'" type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "Article",
          "headline": "Work done and Efficiency derivation of Pelton Turbine | The Mechanical Genius",
          "description": "Work done and hydraulic efficiency derivation of Pelton Turbine with inlet and outlet velocity triangles",
          "author": {
            "@type": "Organization",
            "name": "The Mechanical Genius"
          },
          "publisher": {
            "@type": "Organization",
            "name": "The Mechanical Genius"
          },
          "url": "https://themechanicalgenuis.com/fluid-mechanics/pelton-wheel-derivation",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://themechanicalgenuis.com"
          }
        }
      </component>
    </teleport>
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Fluid Mechanics</h3>
              </div>
  
  
              <div class="article-title">
                <h1>Work done and Efficiency derivation of Pelton Turbine</h1>
              </div>
            </div>
        </div>

        
  
        <div class="article-content-in-full">

          <section class="content-container">
            <div class="diagram">
                <img src="../../assets/images/fluid_mechanics_img/Velocity_triangle_for_pelton_wheel.png" alt="Velocity Triangle for Pelton Wheel">
            </div>
            <p>Where, </p>
            <div ref="mathContainer1"></div>
            <p> </p>
            <p>Velocity triangle at inlet will be a straight line where </p>
            <div class="equations" ref="mathContainer2"></div>

            <p>From velocity triangle at Outlet </p>
            <div class="equations" ref="mathContainer3"></div>
            <p>where, K = blade friction coefficient (ideally K=1)</p>

            <h3>Now, work done by Pelton wheel can be derived as:</h3>
            <p>Force exerted by jet of water in direction of motion is given by</p>
            <div class="equations" ref="mathContainer4"></div>
            <p>Work done by jet on runner per second</p>
            <div class="equations" ref="mathContainer5"></div>
            <p>Work done per second per unit weight of water striking</p>
            <div class="equations" ref="mathContainer6"></div>
            
          
          
          </section>

          <section class="content-container">
            <h2>Hydraulic Efficiency</h2>
            <p>Kinetic energy of jet per second</p>
            <div class="equations" ref="mathContainer7"></div>
            <p>Hydraulic Efficiency</p>
            <div class="equations" ref="mathContainer8"></div>
            <p>From, inlet and outlet velocity triangles, we have</p>
            <div class="equations" ref="mathContainer9"></div>
            <p>Substituting above values in hydraulic efficiency equation,</p>
            <div class="equations" ref="mathContainer10"></div>
          
          
          </section>

        </div>
      </div>
  
      
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';
import { onMounted, ref } from 'vue';
import katex from 'katex';
import 'katex/dist/katex.min.css';



export default {
  name: 'PeltonWheelDerivation',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    const mathContainer1 = ref(null);
    const mathContainer2 = ref(null);
    const mathContainer3 = ref(null);
    const mathContainer4 = ref(null);
    const mathContainer5 = ref(null);
    const mathContainer6 = ref(null);
    const mathContainer7 = ref(null);
    const mathContainer8 = ref(null);
    const mathContainer9 = ref(null);
    const mathContainer10 = ref(null);
    const mathContainer11 = ref(null);


    const derivationTerms = `\\small
    \\text{N = Speed of wheel in r.p.m} \\\\
    \\text{D = Diameter of wheel in m} \\\\
    \\text{d = diameter of jet in m} \\\\
    \\text{u = velocity of runner} \\ = \\frac{\\pi DN}{60} \\ (u_1 = u_2 = u) \\\\
    V_1 = \\text{Absolute velocity of water at inlet} \\\\
    V_{r1} = \\text{Relative velocity of water at inlet w.r.t vane} \\\\
    \\alpha = \\text{Guide angle at inlet} \\\\
    \\theta = \\text{Vane angle at inlet} \\\\
    V_{w1} \\ \\text{and} \\ V_{f1} = \\text{Component of Velocity} \\ V_1 \\ \\text{in direction of motion and perpendicular to motion of vane respectively} \\\\
    V_2 = \\text{Absolute velocity of water at outlet} \\\\
    V_{r1} = \\text{Relative velocity of water at outlet w.r.t. vane} \\\\
    \\phi = \\text{Angle made by relative velocity at outlet} \\ V_{r2} \\ \\text{with direction of motion of vane at outlet} \\\\
    \\beta = \\text{Angle made by absolute velocity at outlet} \\ V_2 \\ \\text{with direction of motion of vane at outlet} \\\\
    V_{w2} \\ \\text{and} \\ V_{f2} = \\text{Component of Velocity} \\ V_2 \\ \\text{in direction of motion and perpendicular to motion of vane respectively} \\\\
    `;

    const inlet = `
    V_{w1} = V_{r1}\\\\
    V_{r1} = V_1 - u \\\\
    \\alpha = 0 \\ \\text{and} \\ \\theta = 0
    `;

    const outlet = `
    V_{w2} = V_{r2} \\cos \\phi - u \\\\
    V_{r2} = K \\times V_{r1}`;

    const forceDerivation = `
    \\text{F = Mass Density *  Velocity} \\\\[10pt]
    F = \\rho \\times A \\times V_{1} \\times (V_{w1} + V_{w2}) \\\\
    `;

    const workDoneDerivation = `
    Work \\ Done = F \\times u =  \\rho \\times A \\times V_{1} \\times (V_{w1} + V_{w2}) \\times u\\\\`;

    const workdoneperunitperweight = `\\Large
    = \\frac{\\rho A V_{1} \\times (V_{w1} + V_{w2}) \\times u }{\\text{Weight of water striking}} = \\frac{\\rho A  V_{1} \\times (V_{w1} + V_{w2}) \\times u } {\\rho A V_{1} \\times g} \\\\[15pt]
    = \\frac{(V_{w1} + V_{w2}) \\times u }{g}\\\\`;

    const kineticEnergy = `
    = \\frac{1}{2} \\times \\rho A V_{1} \\times (V_{w1})^2 \\\\`;

    const HydraulicEfficiency = `
    \\small{\\text{Hydraulic Efficiency,} \\ \\eta_{h} = \\large{\\frac{\\text{Work done on wheel per second}}{\\text{Kinetic energy of jet per second}}}} \\\\[15pt]
    \\large{= \\frac{\\rho A V_{1} \\times (V_{w1} + V_{w2}) \\times u}{\\frac{1}{2} \\times \\rho A V_{1} \\times (V_{1})^2}} \\\\[20pt]
    \\eta_{h}= \\large{\\frac{2(V_{w1} + V_{w2}) \\times u}{V_{1}^2}} \\\\
    `;

    const inletandOutletvelocityTriangle = `
    V_{w1} = V_{1} \\ \\text{and} \\ V_{r1} = V_1 - u\\\\[10pt]
    V_{w2} = V_{r2}\\cos{\\phi} - u = KV_{r1}\\cos{\\phi} - u = K(V_1 - u)\\cos{\\phi} - u\\\\`;

    const finalhydraulicefficiency = `\\large
    \\eta_{h} = \\frac{2[V_1 + K(V_1 - u)\\cos{\\phi} - u]u}{V_1^2}\\\\[10pt]
    = \\frac{2[(V_1 - u) (1 + K\\cos{\\phi})u]}{V_1^2}\\\\`;




    onMounted(() => {
      if (mathContainer1.value) {
        katex.render(derivationTerms, mathContainer1.value, {
          throwOnError: false
        });
      }
      if (mathContainer2.value) {
        katex.render(inlet, mathContainer2.value, {
          throwOnError: false
        });
      }
      if (mathContainer3.value) {
        katex.render(outlet, mathContainer3.value, {
          throwOnError: false
        });
      }
      if (mathContainer4.value) {
        katex.render(forceDerivation, mathContainer4.value, {
          throwOnError: false
        });
      }
      if (mathContainer5.value) {
        katex.render(workDoneDerivation, mathContainer5.value, {
          throwOnError: false
        });
      }
      if (mathContainer6.value) {
        katex.render(workdoneperunitperweight, mathContainer6.value, {
          throwOnError: false
        });
      }
      if (mathContainer7.value) {
        katex.render(kineticEnergy, mathContainer7.value, {
          throwOnError: false
        });
      }
      if (mathContainer8.value) {
        katex.render(HydraulicEfficiency, mathContainer8.value, {
          throwOnError: false
        });
      }
      if (mathContainer9.value) {
        katex.render(inletandOutletvelocityTriangle , mathContainer9.value, {
          throwOnError: false
        });
      } 
      if (mathContainer10.value) {
        katex.render(finalhydraulicefficiency, mathContainer10.value, {
          throwOnError: false
        });
      }
    });

    useHead({
      title: 'Work done and Efficiency derivation of Pelton Turbine | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'Work done and hydraulic efficiency derivation of Pelton Turbine with inlet and outlet velocity triangles',
        },
        {
          name: 'keywords',
          content: 'Work Done by Pelton Turbine, Efficiency of Pelton Turbine, Pelton Wheel Derivation, Fluid Mechanics, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'Work done and Efficiency derivation of Pelton Turbine | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'Work done and hydraulic efficiency derivation of Pelton Turbine with inlet and outlet velocity triangles',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });
    return {
      mathContainer1,
      mathContainer2,
      mathContainer3,
      mathContainer4,
      mathContainer5,
      mathContainer6,
      mathContainer7,
      mathContainer8,
      mathContainer9,
      mathContainer10,
      mathContainer11
    };
    
  },

}
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    .content-container h3{
      font-size: 1.1em;
      color: #000d47;
      font-weight: 200;
    }
    
    
    li, p, ul {
      font-size: 1em;
      line-height: 1.75;
      letter-spacing: 0.025em;
      font-family: serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    .equations{
      text-align: center;
    }

    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .content-container ol{
      padding-inline-start: 1em;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }


  }
    </style>
    