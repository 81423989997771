<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/heat-transfer/modes-of-heat-transfer'"
      :headline="'Conduction, Convection and Radiation - Heat Transfer | The Mechanical Genius'"
      :description="'Heat transfer occurs through three primary modes: conduction, convection, and radiation. A brief overview of each mode, including definitions, mathematical equations, and examples.'"
      :keywords="['Conduction', 'Convection', 'Radiation', 'Heat Transfer', 'Mechanical Engineering']"
      :topic="'Heat Transfer'"
      :topiclink="'https://themechanicalgenuis.com/heat-transfer'"
      :question="'What are different modes of Heat Transfer?'"
      :answer="'Three modes of heat transfer are - Conduction, Convection, and Radiation. Each mode has its unique characteristics and applications. Conduction occurs through a medium in direct contact, convection through a fluid, and radiation through electromagnetic waves. Examples include a metal rod heating up, boiling water, and the Sun warming the Earth.'"
    />
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Heat Transfer</h3>
              </div>
  
  
              <div class="article-title">
                <h1>What are different modes of Heat Transfer?</h1>
              </div>
            </div>
        </div>

        
  
        <div class="article-content-in-full">

          <section class="content-container">
          <h2>Three modes of heat transfer are -</h2>
          <ol>
            <li>Conduction</li>
            <li>Convection</li>
            <li>Radiation</li>
          </ol>
          
        </section>


          <section class="content-container">
            <h2>Conduction</h2>
            <p>It is defined as a method of heat transfer through medium in direct contact, without any movement of material itself.</p>
            <p>Conduction is mathematically defined by <strong>Fourier Law of Heat Conduction</strong></p>
            <div class='equations' ref="mathContainer1"></div>
            <p>where:</p>
            <ul>
              <li><strong ref="mathContainer5"></strong> = Thermal conductivity of the material (W/m·K)</li>
              <li><strong ref="mathContainer11"></strong> = Cross-sectional area through which heat is transferred (m²)</li>
              <li><strong ref="mathContainer4"></strong> = Temperature gradient of the system (K/m)</li>
            </ul>
            <h3>Example:</h3>
            <p>A metal rod heated at one end will transfer heat through conduction along its length. The heat flows from the hot end to the cooler end due to the temperature difference.</p>
          </section>

          <section class="content-container">
            <h2>Convection</h2>
            <p>It is defined as a method of heat transfer through a fluid (liquid or gas) due to the movement of the fluid itself. There are two types of convection : Natural (Free) Convection and Forced Convection.</p>
            <p>Convection is mathematically defined by <strong>Newton's Law of Cooling</strong></p>
            <div class='equations' ref="mathContainer2"></div>
            <p>where:</p>
            <ul>
              <li><strong ref="mathContainer7"></strong> = Convection heat transfer coefficient, W/m²·°C</li>
              <li><strong ref="mathContainer6"></strong> = Cross-sectional area through which convection heat is transferred (m²)</li>
              <li><strong ref="mathContainer8"></strong> = Difference between surface temperature and temperature of the environment (°C)</li>
            </ul>
            <h3>Example:</h3>
            <p>Boiling water in a pot. The heat from the stove warms the bottom of the pot, causing the water near the bottom to rise and cooler water to sink, creating a convection current that evenly distributes the heat. (Note: Heat from water at bottom of pot to top is convection, heat from flame to pot is still conduction.)</p>
          </section>


          <section class="content-container">
            <h2>Radiation</h2>
            <p>It is defined as a method of heat transfer through electromagnetic waves. It does not require medium and can occur in vaccum.</p>
            <p>Radiation is mathematically defined by <strong>Stefan-Boltzmann law</strong></p>
            <div class='equations' ref="mathContainer3"></div>
            <p>where:</p>
            <ul>
              <li><strong ref="mathContainer9"></strong> = Emissivity of the surface (dimensionless)</li>
              <li><strong ref="mathContainer10"></strong> = Stefan-Boltzmann constant (5.67×10⁻⁸  W/m²·K⁴)</li>
             
            </ul>
            <h3>Example:</h3>
            <p>The heat from the Sun reaching Earth. The Sun emits radiation that travels through the vacuum of space and warms the Earth’s surface upon arrival.</p>
          </section>
  
        
  
        </div>
      </div>
  
      
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';
import { onMounted, ref } from 'vue';
import katex from 'katex';
import 'katex/dist/katex.min.css';



export default {
  name: 'ModesofHeatTransfer',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    const mathContainer1 = ref(null);
    const mathContainer2 = ref(null);
    const mathContainer3 = ref(null);
    const mathContainer4 = ref(null);
    const mathContainer5 = ref(null);
    const mathContainer6 = ref(null);
    const mathContainer7 = ref(null);
    const mathContainer8 = ref(null);
    const mathContainer9 = ref(null);
    const mathContainer10 = ref(null);
    const mathContainer11 = ref(null);


    const conductionEquation = 'Q = -kA \\frac{dT}{dx}'; // Fourier's law
    const convectionEquation = 'Q = hA(T_s - T_\\infty)'; // Newton's Law of Cooling
    const radiationEquation = 'Q = \\epsilon \\sigma A (T^4 - T_0^4)'; // Stefan-Boltzmann Law
    const temperaturegradient = '\\frac{dT}{dx}';
    const thermalconductivity = 'k';
    const crosssectionalarea = 'A';
    const heattransfercoeff = 'h';
    const tempdiff = 'T_s - T_\\infty';
    const epsilon = '\\epsilon';
    const sigma = '\\sigma';




    onMounted(() => {
      if (mathContainer1.value) {
        katex.render(conductionEquation, mathContainer1.value, {
          throwOnError: false
        });
      }
      if (mathContainer2.value) {
        katex.render(convectionEquation, mathContainer2.value, {
          throwOnError: false
        });
      }
      if (mathContainer3.value) {
        katex.render(radiationEquation, mathContainer3.value, {
          throwOnError: false
        });
      }
      if (mathContainer4.value) {
        katex.render(temperaturegradient, mathContainer4.value, {
          throwOnError: false
        });
      }
      if (mathContainer5.value) {
        katex.render(thermalconductivity, mathContainer5.value, {
          throwOnError: false
        });
      }
      if (mathContainer6.value) {
        katex.render(crosssectionalarea, mathContainer6.value, {
          throwOnError: false
        });
      }
      if (mathContainer7.value) {
        katex.render(heattransfercoeff, mathContainer7.value, {
          throwOnError: false
        });
      }
      if (mathContainer8.value) {
        katex.render(tempdiff, mathContainer8.value, {
          throwOnError: false
        });
      }
      if (mathContainer9.value) {
        katex.render(epsilon, mathContainer9.value, {
          throwOnError: false
        });
      } 
      if (mathContainer10.value) {
        katex.render(sigma, mathContainer10.value, {
          throwOnError: false
        });
      }
      if (mathContainer11.value) {
        katex.render(crosssectionalarea, mathContainer11.value, {
          throwOnError: false
        });
      }
    });

    useHead({
      title: 'Conduction, Convection and Radiation - Heat Transfer | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'Heat transfer occurs through three primary modes: conduction, convection, and radiation. A brief overview of each mode, including definitions, mathematical equations, and examples',
        },
        {
          name: 'keywords',
          content: 'Conduction, Convection , Radiation, Heat Transfer, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'Conduction, Convection and Radiation - Heat Transfer | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'Heat transfer occurs through three primary modes: conduction, convection, and radiation. A brief overview of each mode, including definitions, mathematical equations, and examples',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });
    return {
      mathContainer1,
      mathContainer2,
      mathContainer3,
      mathContainer4,
      mathContainer5,
      mathContainer6,
      mathContainer7,
      mathContainer8,
      mathContainer9,
      mathContainer10,
      mathContainer11
    };
    
  },

}
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    .content-container h3{
      font-size: 1.1em;
      color: #000d47;
      font-weight: 200;
    }
    
    
    li, p, ul {
      font-size: 1em;
      line-height: 1.75;
      letter-spacing: 0.025em;
      font-family: serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    .equations{
      text-align: center;
    }

    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .content-container ol{
      padding-inline-start: 1em;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }


  }
    </style>
    