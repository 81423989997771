<template>
<Header_bar />
<SeoComponent
      :link="'https://themechanicalgenuis.com/machine-design/flywheel-vs-governer'"
      :headline="'Difference between functioning of Flywheel and Governer | The Mechanical Genius'"
      :description="'Flywheel is used to store rotational energy and maintain uniform angular velocity of the engine while governor is used to regulate and control the mean speed of an engine by adjusting the fuel supply.'"
      :keywords="['Flywheel', 'Governer', 'Difference between flywheel and governer', 'Machine Design', 'Mechanical Engineering']"
      :topic="'Machine Design'"
      :topiclink="'https://themechanicalgenuis.com/machine-design'"
      :question="'What is difference between functioning of Flywheel and Governer?'"
      :answer="'Flywheel is used to store rotational energy and maintain uniform angular velocity of the engine while governor is used to regulate and control the mean speed of an engine by adjusting the fuel supply.'"
    />

    <div class="article-layout">

    <div class="heading-container">
        <div class="header-spacer"></div>
        
        <div class="headline">
            <div class="article-topic">
            <h3>Machine Design</h3>
            </div>


            <div class="article-title">
            <h1>Difference between functioning of Flywheel and Governer</h1>
            </div>
        </div>
    </div>

    <div class="article-content-in-full">
        <section class="content-container">
            <table>
                <thead>
                    <tr>
                    <th>S.No.</th>
                    <th>Flywheel</th>
                    <th>Governer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>1</td>
                    <td>A flywheel is used to store rotational energy and maintain uniform angular velocity of the engine.</td>
                    <td>A governor is used to regulate and control the mean speed of an engine by adjusting the fuel supply.</td>
                    </tr>
                    <tr>
                    <td>2</td>
                    <td>It works by absorbing excess energy when the engine speed is high and releasing it when the engine speed drops.</td>
                    <td>It works by adjusting the fuel supply to maintain a nearly constant speed regardless of load variations.</td>
                    </tr>
                    <tr>
                    <td>3</td>
                    <td>Flywheel operates on the principle of inertia.</td>
                    <td>Governer operates by sensing changes in engine speed and adjusting the throttle or fuel valve accordingly. It consists of rotating weights, linkages, and a control mechanism.</td>
                    </tr>
                    <tr>
                    <td>4</td>
                    <td>Flywheel is mounted on the engine crankshaft.</td>
                    <td>Governer is installed near the fuel supply system or linked to it.</td>
                    </tr>
                    <tr>
                    <td>5</td>
                    <td>It controls the cyclic speed variation.</td>
                    <td>It controls the mean speed based on load conditions.</td>
                    </tr>
                </tbody>
            </table>
        
        </section>
    </div>

</div>

    
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';
export default {
    name: 'FlywheelVsGoverner',
    components:{
    Header_bar,
    SeoComponent
    },
    setup() {
    useHead({
    title: 'Difference between functioning of Flywheel and Governer | The Mechanical Genius',
    meta: [
        {
        name: 'description',
        content: 'Flywheel is used to store rotational energy and maintain uniform angular velocity of the engine while governor is used to regulate and control the mean speed of an engine by adjusting the fuel supply.',
        },
        {
        name: 'keywords',
        content: 'Flywheel, Governer, Difference between Flywheel and Governer, Machine Design, Mechanical Engineering',
        },
        {
        property: 'og:title',
        content: 'Difference between functioning of Flywheel and Governer | The Mechanical Genius',
        },
        {
        property: 'og:description',
        content: 'Flywheel is used to store rotational energy and maintain uniform angular velocity of the engine while governor is used to regulate and control the mean speed of an engine by adjusting the fuel supply.',
        },
        {
        property: 'og:type',
        content: 'article',
        }
    ],
    });
},
};
</script>

<style scoped>
.article-layout{
    margin: 0 5em;
}

.heading-container{
    width: 100%;
    height: 20em;

}

.header-spacer{
    width: 100%;
    height: 10em;
}

.headline{
    padding: 0 0em;
}

.content-container{
    padding-bottom: 1em;
}

.article-topic h3{
    font-size: 1em;
    color: #309c99;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 200;

}

.article-title h1{
    font-size: 2.5em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
}

.article-content-in-full{
    width: 75%;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

.article-content-in-full h2{
    font-weight: 200;
    font-size: 1.5em;
    color: #000d47;
    letter-spacing: 0.01em;
    }



p, ul {
    font-size: 1.1em;
    line-height: 1.75;
    text-align: justify;
    letter-spacing: 0.025em;
    font-family: serif;
}

ul {
    list-style-type: disc;
    margin-left: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

}


table, th, td {
    border: 1px solid black;
    font-family: serif;
    font-weight: 100;
    font-size: 1em;
    line-height: 1.75;
    letter-spacing: 0.025em;
}

th, td {
    padding: 10px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}


@media (max-width: 768px) {
    .article-layout{
    padding: 0 1em;
    margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
    width: 100%;
    height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
    width: 100%;
    }

    th, td {
    padding: 6px;
    text-align: left;
    }

    p, ul, li {
font-size: 0.9em;
line-height: 1.75;
font-family: serif;
}


}
</style>
    