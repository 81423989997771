// List of featured posts on the home page

export default [
    { 
      id: 1, 
      link_to: '/thermodynamics/zeroth-law-of-thermodynamics' ,
      topic:"Thermodynamics", name: 'What is Zeroth Law of Thermodynamics?', 
      image_link: require('@/assets/images/home_page.jpg'), 
      image_title: "Zeroth Law of Thermodynamics", 
      description:'Zeroth Law of Thermodynamics state that if two thermodynamic system are both in thermal equilibrium with third system, then these two system are in thermal equilibrium with each other.'
    },
    { id: 2,
      link_to: '/thermodynamics/second-law-of-thermodynamics', 
      topic:"Thermodynamics", 
      name: 'What is Second Law of Thermodynamics?', 
      image_link: require('@/assets/images/thermodynamics_img/carnot_heat_engine.png'), 
      image_title: "Caront Heat Engine", 
      description:'The Second Law of Thermodynamics states that total entropy of universe never decrease over time. It implies that natural processes tend to move towards a state of greater disorder or entropy.'
    },
    {
      id: 3,
      link_to: '/heat-transfer/critical-thickness-of-insulation',
      topic:"Heat Transfer", 
      name: 'What is Critical Thickness of Insulation?', 
      image_link: require('@/assets/images/heat_transfer_img/critical_thickness_of_insulation.png'), 
      image_title: "Critical Thickness of Insulation", 
      description:'The critical thickness of insulation refers to the thickness of insulation at which the heat loss from a surface is minimized. Beyond this critical thickness, any further increase in insulation thickness results in an increase in heat loss.'
    },
    {
      id: 4,
      link_to: '/fluid-mechanics/pelton-wheel-derivation',
      topic:"Fluid Mechanics", 
      name: 'Work done and Efficiency derivation of Pelton Turbine', 
      image_link: require('@/assets/images/fluid_mechanics_img/Velocity_triangle_for_pelton_wheel.png'), 
      image_title: "Velocity triangle for Pelton Wheel", 
      description:'The Pelton wheel is an impulse turbine used for high head, low flow applications. The derivation of work done and efficiency of Pelton turbine is based on the principle of conservation of energy.'

    }

    // Add more posts here
  ];