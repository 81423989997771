<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/fluid-mechanics/pelton-vs-francis-turbine'"
      :headline="'Difference between Pelton and Francis Turbine | The Mechanical Genius'"
      :description="'Difference on the basis of type, head, runner, direction of flow of water through the blades, power generation, speed, discharge required, type of energy, efficiency etc.'"
      :keywords="['Pelton Turbine', 'Francis Turbine','Fluid Mechanics', 'Mechanical Engineering']"
      :topic="'Fluid Mechanics'"
      :topiclink="'https://themechanicalgenuis.com/fluid-mechanics'"
      :question="'Difference between Pelton and Francis Turbine'"
      :answer="'Pelton turbine is an impulse type water turbine while francis Turbine is an inward flow reaction turbine'"
    />
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Fluid Mechanics</h3>
              </div>
  
  
              <div class="article-title">
                <h1>Difference between Pelton and Francis Turbine</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
            <section class="content-container">
              <table>
                    <thead>
                        <tr>
                        <th>S.No.</th>
                        <th>Criteria</th>
                        <th>Pelton Turbine</th>
                        <th>Francis Turbine</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>1</td>
                        <td>Type</td>
                        <td>Pelton turbine is an impulse type water turbine</td>
                        <td>Francis Turbine is an inward flow reaction turbine</td>
                        </tr>
                        <tr>
                        <td>2</td>
                        <td>Head</td>
                        <td>It is used for high heads ranging from 250 to 1000m</td>
                        <td>The operating water head of a Francis turbine ranges from 40 to 600m</td>
                        </tr>
                        <tr>
                        <td>3</td>
                        <td>Discharge Required</td>
                        <td>The required discharge for the working of a Pelton turbine is low.</td>
                        <td>Medium discharge is required for the working of the Francis turbine</td>
                        </tr>
                        <tr>
                        <td>4</td>
                        <td>Direction of Flow of Water Through the Blades</td>
                        <td>The flow of water is tangential to the runner. Hence it is also called as tangential flow impulse turbines</td>
                        <td>The flow of water through the blades combines both radial and axial flow.</td>
                        </tr>
                        <tr>
                        <td>5</td>
                        <td>Type of Energy</td>
                        <td>The Pelton turbines uses kinetic energy and converts into mechanical energy</td>
                        <td>The Francis turbine converts potential energy into mechanical energy</td>
                        </tr>
                        <tr>
                        <td>6</td>
                        <td>Efficiency</td>
                        <td>The efficiency of a Pelton turbine is about 85%</td>
                        <td>The efficiency of a Francis turbine is about 90%.</td>
                        </tr>
                    </tbody>
                </table>
            
            </section>
        </div>
    
    </div>
  
      
    </template>
    
    <script>
    import SeoComponent from '@/components/ArticleSeo.vue';
    import Header_bar from '@/layouts/header.vue';
    import { useHead } from '@vueuse/head';
    export default {
      name: 'PeltonvsFrancisTurbine',
      components:{
        Header_bar,
        SeoComponent
      },
      setup() {
      useHead({
        title: 'Difference between Pelton and Francis Turbine | The Mechanical Genius',
        meta: [
          {
            name: 'description',
            content: 'Difference on the basis of type, head, runner, direction of flow of water through the blades, power generation, speed, discharge required, type of energy, efficiency etc.',
          },
          {
            name: 'keywords',
            content: 'Pelton Turbine, Francis Turbine, Fluid Mechanics, Mechanical Engineering',
          },
          {
            property: 'og:title',
            content: 'Difference between Pelton and Francis Turbine | The Mechanical Genius',
          },
          {
            property: 'og:description',
            content: 'Difference on the basis of type, head, runner, direction of flow of water through the blades, power generation, speed, discharge required, type of energy, efficiency etc.',
          },
          {
            property: 'og:type',
            content: 'article',
          }
        ],
      });
    },
    };
    </script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    
    
    p, ul {
      font-size: 1.1em;
      line-height: 1.75;
      text-align: justify;
      letter-spacing: 0.025em;
      font-family: serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;

    }

    
    table, th, td {
      border: 1px solid black;
      font-family: serif;
      font-weight: 100;
      font-size: 1em;
      line-height: 1.75;
      letter-spacing: 0.025em;
    }

    th, td {
      padding: 10px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
    }

    
    @media (max-width: 768px) {
      .article-layout{
        padding: 0 1em;
        margin: 0;
      }
  
      .heading-container{
      width: 100%;
      height: 20em;
      }
  
      .header-spacer{
        width: 100%;
        height: 5em;
      }
  
      .article-title h1{
      font-size: 2em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
      }
  
      .article-content-in-full{
        width: 100%;
      }

      th, td {
      padding: 6px;
      text-align: left;
      }

      p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }


    }
    </style>
    