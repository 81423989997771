<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/machine-design/endurance-limit'"
      :headline="'What is Endurance Limit? What are factors affecting endurance limit | The Mechanical Genius'"
      :description="'The endurance limit is the maximum stress level that a material can withstand for an infinite number of cycles without failure'"
      :keywords="['Endurance Limit', 'Max Stress Level', 'Machine Design', 'Mechanical Engineering']"
      :topic="'Machine Design'"
      :topiclink="'https://themechanicalgenuis.com/machine-design'"
      :question="'What is Endurance Limit? What are factors affecting endurance limit?'"
      :answer="'The endurance limit is the maximum stress level that a material can withstand for an infinite number of cycles without failure.'"
    />
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Machine Design</h3>
              </div>
  
  
              <div class="article-title">
                <h1>What is Endurance Limit? What are factors affecting endurance limit?</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
          <section class="content-container">
            <h2>Definition</h2>
            <p>The endurance limit is the maximum stress level that a material can withstand for an infinite number of cycles without failure. For many materials, the endurance limit S<sub>e</sub> can be estimated from the ultimate tensile strength S<sub>ut</sub> using the equation:</p>
            <div class="equation" ref="mathContainer1"></div>
          </section>

          
  
          <section class="content-container">
            <h2>Factors affecting endurance limit</h2>
            <div ref='mathContainer2'></div>
            <ol>
                <li>
                    <h3>Surface Finish</h3>
                    <p>The condition of the material's surface (roughness, presence of notches, etc.) plays a critical role in endurance strength. A smoother surface typically results in a higher endurance limit.</p>
                </li>
                
                <li>
                    <h3>Material Composition</h3>
                    <p>The chemical composition of the material significantly affects its endurance properties. Alloys and heat treatments can enhance endurance limits.</p>
                </li>

                <li>
                    <h3>Loading Conditions</h3>
                    <p>The type of loading (tensile, compressive, or bending) also influences the endurance limit.</p>
                </li>

                <li>
                    <h3>Frequency of Loading</h3>
                    <p>The rate at which cyclic loads are applied can also influence endurance limits.</p>
                </li>

                <li>
                    <h3>Notch Sensitivity</h3>
                    <p>The presence and severity of notches or defects can lower the endurance limit due to localized stress concentrations.</p>
                </li>
            </ol>
            
          </section>
        
        </div>
      </div>
  
      
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';
import { onMounted, ref } from 'vue';
import katex from 'katex';
import 'katex/dist/katex.min.css';

export default {
  name: 'ModesofHeatTransfer',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    const mathContainer1 = ref(null);
    const endurancelimit = 'S_{e} ≈ 0.5 * S_{ut}'

    onMounted(() => {
      if (mathContainer1.value) {
        katex.render(endurancelimit, mathContainer1.value, {
          throwOnError: false
        });
      }
    });

    useHead({
      title: 'What is Endurance Limit? What are factors affecting endurance limit | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'The endurance limit is the maximum stress level that a material can withstand for an infinite number of cycles without failure',
        },
        {
          name: 'keywords',
          content: 'Endurance Limit, Machine Design, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'What is Endurance Limit? What are factors affecting endurance limit | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'The endurance limit is the maximum stress level that a material can withstand for an infinite number of cycles without failure',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });

    return {
      mathContainer1
    };
  },

}
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    
    
    li, p, ul {
      font-size: 1em;
      line-height: 1.75;
      letter-spacing: 0.025em;
      font-family: serif;
      margin-top: 0;
     
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    .content-container h3{
      font-size: 1.1em;
      color: #000d47;
      font-weight: 200;
      margin: 0;
    }

    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .content-container ol{
      padding-inline-start: 1em;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }


  }
    </style>
    