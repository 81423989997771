<template>
    <Header_bar />
    <teleport to="head">
      <component :is="'script'" type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "Article",
          "headline": "What is Ignition Timing? What are the various factors which affects Ignition Timing | The Mechanical Genius",
          "description": "Ignition Timing refers to the precise moment when the spark plug in an IC engine ignites the air-fuel mixture.",
          "author": {
            "@type": "Organization",
            "name": "The Mechanical Genius"
          },
          "publisher": {
            "@type": "Organization",
            "name": "The Mechanical Genius"
          },
          "url": "https://themechanicalgenuis.com/ic-engine/ignition-timing",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://themechanicalgenuis.com"
          }
        }
      </component>
    </teleport>
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>IC Engine</h3>
              </div>
  
  
              <div class="article-title">
                <h1>What is Ignition Timing? What are the various factors which affects Ignition Timing?</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
          <section class="content-container">
            <h2>Definition</h2>
            <p>Ignition Timing refers to the precise moment when the spark plug in an IC engine ignites the air-fuel mixture. Ignition timing is usually specified in degrees of crankshaft rotation before or after the piston reaches the top dead center (TDC) of the compression stroke. This timing is critical for efficiency, power and fuel economy of the engine. </p>
          </section>
  
          <section class="content-container">
            <h2>Factors on which Detonation depends</h2>
            <ol>
              <li><strong>Engine Load</strong></li><p>Under heavy loads, the ignition timing is retarded (set later) to prevent knocking and ensure smooth engine operation. At lighter loads, the timing can be advanced (set earlier) for better efficiency.</p>
              <li><strong>Engine Speed (RPM)</strong></li><p>At higher RPMs, the air-fuel mixture is ignited earlier to ensure complete combustion due to the reduced time available for the combustion process. Thus, ignition timing is advanced at higher RPMs.</p>
              <li><strong>Air-Fuel Mixture</strong></li><p>A lean mixture (more air, less fuel) requires advanced timing. And rich mixture (less air, more fuel) need retarded timing.</p>
              <li><strong>Engine Temperature</strong></li><p>At High engine temperatures, retarded timing is prefered. At low temperatures, advanced timing is required to compensate for the slower combustion.</p>
            </ol>
          </section>
        
        <section class="content-container">
          <h2>Ignition timing can be controlled by</h2>
          <p>Modern engines often use <strong>electronic control units (ECUs)</strong> to dynamically adjust ignition timing based on real-time data from various sensors, ensuring optimal performance under a wide range of operating conditions.</p>
          
        </section>

        <section class="content-container">
          <h2>Why proper Ignition Timing is important?</h2>
          <p>Proper ignition timing ensures that the air-fuel mixture burns efficiently, maximizing power output and fuel efficiency while minimizing emissions. Incorrect timing can lead to problems such as knocking (pre-detonation), reduced engine performance, increased fuel consumption, and higher emissions.</p>

        </section>
          
        </div>
      </div>
  
      
</template>
    
<script>
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'IgnitionTiming',
  components:{
    Header_bar
  },
  setup() {
    useHead({
      title: 'What is Ignition Timing? What are the various factors which affects Ignition Timing | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'Ignition Timing refers to the precise moment when the spark plug in an IC engine ignites the air-fuel mixture',
        },
        {
          name: 'keywords',
          content: 'Ignition Timing, Factors affecting Ignition Timing, Internal Combustion Engine, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'What is Ignition Timing? What are the various factors which affects Ignition Timing | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'Ignition Timing refers to the precise moment when the spark plug in an IC engine ignites the air-fuel mixture',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });
  },
}
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    
    
    li, p, ul {
      font-size: 1.1em;
      line-height: 1.75;
      text-align: justify;
      letter-spacing: 0.025em;
      font-family: serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 22em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .content-container ol{
      padding-inline-start: 1em;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }


  }
    </style>
    