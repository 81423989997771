<template>
  <Header_bar />
  <SeoComponent
      :link="'https://themechanicalgenuis.com/thermodynamics/first-law-of-thermodynamics'"
      :headline="'What is First Law of Thermodynamics | The Mechanical Genius'"
      :description="'The First Law of Thermodynamics states that energy cannot be created or destroyed in an isolated system.'"
      :keywords="['First Law of Thermodynamics', 'Thermodynamics', 'Mechanical Engineering']"
      :topic="'Thermodynamics'"
      :topiclink="'https://themechanicalgenuis.com/thermodynamics'"
      :question="'What is First Law of Thermodynamics?'"
      :answer="'The First Law of Thermodynamics states that energy cannot be created or destroyed in an isolated system. Energy can be transformed from one form to another. It is also known as the Law of Conservation of Energy. This law is given by Rudolf Clausius in 1850.'"
    />
  
    <div class="article-layout">

      <div class="heading-container">
        <div class="header-spacer"></div>
        
          <div class="headline">
            <div class="article-topic">
              <h3>Thermodynamics</h3>
            </div>


            <div class="article-title">
              <h1>What is First Law of Thermodynamics?</h1>
            </div>
          </div>
      </div>

      <div class="article-content-in-full">
        <section class="content-container">
          <h2>Definition</h2>
          <p>The First Law of Thermodynamics states that energy cannot be created or destroyed in an isolated system. Energy can be transformed from one form to another. It is also known as the Law of Conservation of Energy. This law is given by Rudolf Clausius in 1850.</p>
        </section>

        <section class="content-container">
        <h2>Equation</h2>
        <p>The mathematical expression for the First Law of Thermodynamics is:</p>
        <p><strong>ΔU = Q - W</strong></p>
        <p>where:</p>
        <ul>
          <li><strong>ΔU</strong> = Change in internal energy of the system</li>
          <li><strong>Q</strong> = Heat added to the system</li>
          <li><strong>W</strong> = Work done by the system</li>
        </ul>
      </section>
      
      <section class="content-container">
        <h2>Example</h2>
        <p>Consider a gas contained in a piston. If 500 J of heat is added to the gas and the gas does 200 J of work on the piston, the change in internal energy of the gas can be calculated as:</p>
        <p>ΔU = Q - W = 500 J - 200 J = 300 J</p>
        <p>Therefore, the internal energy of the gas increases by 300 J.</p>
      </section>

      </div>
    </div>

    
  </template>
  
  <script>
  import SeoComponent from '@/components/ArticleSeo.vue';
  import Header_bar from '@/layouts/header.vue';
  import { useHead } from '@vueuse/head';
  export default {
    name: 'FirstLawOfThermodynamics',
    components:{
      Header_bar,
      SeoComponent
    },
    setup() {
    useHead({
      title: 'What is First Law of Thermodynamics | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'The First Law of Thermodynamics states that energy cannot be created or destroyed in an isolated system.',
        },
        {
          name: 'keywords',
          content: 'First Law of Thermodynamics, Laws of Thermodynamics, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'What is First Law of Thermodynamics | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'The First Law of Thermodynamics states that energy cannot be created or destroyed in an isolated system.',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });
  },
  };
  </script>
  
  <style scoped>
  .article-layout{
    margin: 0 5em;
  }

  .heading-container{
    width: 100%;
    height: 20em;

  }

  .header-spacer{
    width: 100%;
    height: 10em;
  }

  .headline{
    padding: 0 0em;
  }

  .content-container{
    padding-bottom: 1em;
  }

  .article-topic h3{
    font-size: 1em;
    color: #309c99;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 200;

  }

  .article-title h1{
    font-size: 2.5em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
  }

  .article-content-in-full{
    width: 75%;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  }

  .article-content-in-full h2{
    font-weight: 200;
    font-size: 1.5em;
    color: #000d47;
    letter-spacing: 0.01em;
   }
  
  
  
  p, ul {
    font-size: 1.1em;
    line-height: 1.75;
    text-align: justify;
    letter-spacing: 0.025em;
    font-family: serif;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }





  }
  </style>
  