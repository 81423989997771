<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/machine-design/failure-theory'"
      :headline="'What are different theories of failure | The Mechanical Genius'"
      :description="'Failure theories aim to understand and predict the failure of materials under different types of stress. Five major theories of failure are - Maximum Principal Stress theory or Rankine Theory, Maximum Shear Stress theory or Guest and Tersca Theory, Maximum Principal Strain theory or St. Venant Theory, Total Strain Energy Theory or Haigh Theory, Maximum Distortion Energy Theory or Von Mises Theory'"
      :keywords="['Failure Theory', 'Machine Design', 'Mechanical Engineering']"
      :topic="'Machine Design'"
      :topiclink="'https://themechanicalgenuis.com/machine-design'"
      :question="'What are different theories of failure?'"
      :answer="'Failure theories aim to understand and predict the failure of materials under different types of stress. Five major theories of failure are - Maximum Principal Stress theory or Rankine Theory, Maximum Shear Stress theory or Guest and Tersca Theory, Maximum Principal Strain theory or St. Venant Theory, Total Strain Energy Theory or Haigh Theory, Maximum Distortion Energy Theory or Von Mises Theory'"
    />
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Machine Learning</h3>
              </div>
  
  
              <div class="article-title">
                <h1>What are different theories of failure?</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
          <section class="content-container">
            <h2>Definition</h2>
            <p>Failure theories aim to understand and predict the failure of materials under different types of stress.</p>
          </section>
  
          <section class="content-container">
            <h2>Five major theories of failure are - </h2>
            <ol>
              <li>
                <h3>Maximum Principal Stress theory or Rankine's Theory</h3>
                <p>Rankine's theory states that failure happens when maximum principal stress exceeds ultimate tensile strength of the material. Mathematically, condition for failure is:</p>
                <div class="equations" ref='mathContainer1'></div>
                <p>Note:
                  <ol>
                    <li>This theory is suitable for brittle materials.</li>
                    <li>This theory is not suitable for ductile materials because ductile materials are weak in shear.</li>
                  </ol>
                </p>
              </li>
              <li>
                <h3>Maximum Shear Stress theory or Guest and Tersca's Theory</h3>
                <p>Guest and Tersca's theory states that failure happens when maximum shear stress exceeds yield strength of the material. Mathematically, condition for failure is:</p>
                <div class="equations" ref='mathContainer2'></div>
                <p>Note:
                  <ol>
                    <li>This theory is suitable for ductile materials and gives oversafe design i.e. safe and uneconomic design.</li>
                    <li>This theory is not suitable under hydrostatic stress condition.</li>
                  </ol>
                </p>
              </li>
              <li>
                <h3>Maximum Principal Strain theory or St. Venant's Theory</h3>
                <p>St. Venant's theory states that failure happens when maximum principal strain exceeds yield strain of the material. Mathematically, condition for failure is:</p>
                <div class="equations" ref='mathContainer3'></div>
                <p>where E is Young’s Modulus of Elasticity.</p>
              </li>
              <li>
                <h3>Total Strain Energy Theory or Haigh's Theory</h3>
                <p>Haigh's theory states that failure happens when total strain energy per unit volume exceeds the strain energy per unit volume at yield point. Mathematically, condition for failure is:</p>
                <div class="equations" ref='mathContainer4'></div>
                <p>For triaxial state,</p>
                <div class="equations" ref='mathContainer5'></div>
                <p>OR</p>
                <div class="equations" ref='mathContainer6'></div>
                <p>Note:
                  <ol>
                    <li>Total strain energy theory is suitable under hydrostatic stress condition.</li>
                  </ol>
                </p>
              </li>
              <li>
                <h3>Maximum Distortion Energy Theory or Von Mises Theory</h3>
                <p>Von Mises theory states that failure happens when maximum distortion energy per unit volume exceeds distortion energy per unit volume at yield point. Mathematically, condition for failure is:</p>
                <div class="equations" ref='mathContainer7'></div>
                <p>Note:
                  <ol>
                    <li>This theory is best theory of failure for ductile material. It gives safe and economic design.</li>
                    <li>This theory is not suitable under hydrostatic stress condition.</li>
                  </ol>
                </p>
              </li>

            </ol>
          </section>


        
        
  
        </div>
      </div>
  
      
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';
import { onMounted, ref } from 'vue';
import katex from 'katex';
import 'katex/dist/katex.min.css';

export default {
  name: 'FailureTheory',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    const mathContainer1 = ref(null);
    const mathContainer2 = ref(null);
    const mathContainer3 = ref(null);
    const mathContainer4 = ref(null);
    const mathContainer5 = ref(null);
    const mathContainer6 = ref(null);
    const mathContainer7 = ref(null);


    const rankineTheory = '\\sigma_{1} > S_{ut}';
    const trescaTheory = '\\large \\tau_{max} > \\frac{S_{yt}}{2}';
    const venantTheory = '\\large \\epsilon_{1} > \\frac{S_{yt}}{E}';
    const haighTheory = '\\large \\frac{1}{2} \\sigma_{1} \\epsilon_{1} > \\frac{1}{2} S_{yt} \\epsilon_{yt}';
    const triaxialhaighTheory = '\\large \\frac{1}{2} \\sigma_{1} \\epsilon_{1} + \\frac{1}{2} \\sigma_{2} \\epsilon_{2} + \\frac{1}{2} \\sigma_{3} \\epsilon_{3} > \\frac{1}{2} S_{yt} \\epsilon_{yt}';
    const finalhaighTheory = '\\large \\frac{1}{2} \\sigma_{1} \\epsilon_{1} + \\frac{1}{2} \\sigma_{2} \\epsilon_{2} + \\frac{1}{2} \\sigma_{3} \\epsilon_{3} > \\frac{(S_{yt})^2}{2E}';
    const vonmisesThoery = '\\normalsize \\sqrt{\\frac{1}{2}[ (\\sigma_{1} - \\sigma_{2})^2 + (\\sigma_{2} - \\sigma_{3})^2 + (\\sigma_{3} - \\sigma_{1})^2}] > S_{yt}';
 
    onMounted(() => {
      if (mathContainer1.value) {
        katex.render(rankineTheory, mathContainer1.value, {
          throwOnError: false
        });
      }
      if (mathContainer2.value) {
        katex.render(trescaTheory, mathContainer2.value, {
          throwOnError: false
        });
      }
      if (mathContainer3.value) {
        katex.render(venantTheory, mathContainer3.value, {
          throwOnError: false
        });
      }
      if (mathContainer4.value) {
        katex.render(haighTheory, mathContainer4.value, {
          throwOnError: false
        });
      }
      if (mathContainer5.value) {
        katex.render(triaxialhaighTheory, mathContainer5.value, {
          throwOnError: false
        });
      }
      if (mathContainer6.value) {
        katex.render(finalhaighTheory, mathContainer6.value, {
          throwOnError: false
        });
      }
      if (mathContainer7.value) {
        katex.render(vonmisesThoery, mathContainer7.value, {
          throwOnError: false
        });
      }
    });
    
    useHead({
      title: 'What are different theories of failure | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'Failure theories aim to understand and predict the failure of materials under different types of stress. Five major theories of failure are - Maximum Principal Stress theory or Rankine\'s Theory, Maximum Shear Stress theory or Guest and Tersca\'s Theory, Maximum Principal Strain theory or St. Venant\'s Theory, Total Strain Energy Theory or Haigh\'s Theory, Maximum Distortion Energy Theory or Vonmises Theory',
        },
        {
          name: 'keywords',
          content: 'Failure Theory, Five Major Theories of Failure, Machine Design, , Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'What are different theories of failure | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'Failure theories aim to understand and predict the failure of materials under different types of stress. Five major theories of failure are - Maximum Principal Stress theory or Rankine\'s Theory, Maximum Shear Stress theory or Guest and Tersca\'s Theory, Maximum Principal Strain theory or St. Venant\'s Theory, Total Strain Energy Theory or Haigh\'s Theory, Maximum Distortion Energy Theory or Vonmises Theory',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://themechanicalgenuis.com/machine-design/failure-theory',  // Canonical URL for the homepage
        }
      ]
    });

    return{
      mathContainer1,
      mathContainer2,
      mathContainer3,
      mathContainer4,
      mathContainer5,
      mathContainer6,
      mathContainer7
    }
  },
}
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    
    
    li, p, ul {
      font-size: 1em;
      line-height: 1.75;
      letter-spacing: 0.025em;
      font-family: serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    .equations{
      text-align: center;
      padding-top: 1em;
      padding-bottom: 1em;
    }

    .content-container h3{
      font-size: 1.2em;
      color: #000d47;
      font-weight: 200;
      margin: 0;
    }

    .diagram{
      padding-top: 2em;
      padding-bottom: 2em;
    }


    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .content-container ol{
      padding-inline-start: 1em;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }

    .diagram img{
      width: 100%;
    }


  }
    </style>
    