<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/industrial-engineering/economic-order-quantity'"
      :headline="'Economic Order Quantity | Industrial Engineering | The Mechanical Genius'"
      :description="'Economic Order Quantity (EOQ) is a fundamental concept in inventory management and industrial engineering that determines the optimal order quantity a company should purchase to minimize total inventory costs.'"
      :keywords="['Economic Order Quantity (EOQ)', 'EOQ Model', 'Industrial Engineering', 'Mechanical Engineering']"
      :topic="'Industrial Engineering'"
      :topiclink="'https://themechanicalgenuis.com/industrial-engineering'"
      :question="'What is Economic Order Quantity (EOQ)?'"
      :answer="'Economic Order Quantity (EOQ) is a fundamental concept in inventory management and industrial engineering that determines the optimal order quantity a company should purchase to minimize total inventory costs.'"
    />
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Industrial Engineering</h3>
              </div>
  
  
              <div class="article-title">
                <h1>Economic Order Quantity (EOQ)</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
          <section class="content-container">
            <h2>Definition</h2>
            <p>Economic Order Quantity (EOQ) is a fundamental concept in inventory management and industrial engineering that determines the optimal order quantity a company should purchase to minimize total inventory costs.</p>
          </section>
  
          <section class="content-container">
            <h2>Derivation of Economic Order Quantity (EOQ)</h2>
            <h3>Assumptions :</h3>
            <ol>
              <li>The demand for the product is constant over time.</li>
              <li>The cost to place an order is constant, regardless of the order size.</li>
              <li>The cost to hold one unit of inventory is constant.</li>
              <li>Inventory is replenished instantly when an order is placed i.e. lead time is zero</li>
            </ol>

            <h3>EOQ Model :</h3>
            <img class="diagram" src="../../assets/images/industrial_engineering_img/inventory_graph.gif" alt="EOQ Model">
            <p>Let,</p>
            <ol>
              <li><div ref='mathContainer1'></div></li>
              <li><div ref='mathContainer2'></div></li>
              <li><div ref='mathContainer3'></div></li>
              <li><div ref='mathContainer4'></div></li>
              <li><div ref='mathContainer5'></div></li>
            </ol>
            <p>In EOQ model, average inventory is Q/2 as evident from inventory plot given. So, Total Cost is given by sum of total holding cost and total ordering cost.</p>
            <div class="equations" ref="mathContainer6"></div>
            <p>where,</p>
            <div ref='mathContainer7'></div>
            <p>For minimizing the total cost, differentiate the total cost with respect to Q and equate it to zero.</p>
            <div class="equations" ref="mathContainer8"></div>
            <p>On solving the above equation, we get the Economic Order Quantity (EOQ) as,</p>
            <div class="equations" ref="mathContainer9"></div>
            and, 
            <div class="equations" ref="mathContainer10"></div>

          </section>


        
        
  
        </div>
      </div>
  
      
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';
import { onMounted, ref } from 'vue';
import katex from 'katex';
import 'katex/dist/katex.min.css';

export default {
  name: 'EconomicOrderQuantity',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    const mathContainer1 = ref(null);
    const mathContainer2 = ref(null);
    const mathContainer3 = ref(null);
    const mathContainer4 = ref(null);
    const mathContainer5 = ref(null);
    const mathContainer6 = ref(null);
    const mathContainer7 = ref(null);
    const mathContainer8 = ref(null);
    const mathContainer9 = ref(null);
    const mathContainer10 = ref(null);
   


    const annualDemand = 'D = \\text{Annual Demand}';
    const orderingCost = 'C_o = \\text{Ordering Cost per Order}';
    const holdingCost = 'C_h =  \\text{Holding Cost per Unit}'; 
    const costperunit = 'C = \\text{Cost per Unit}';
    const Q = 'Q = \\text{Order Quantity}';
    const totalCost = '\\normalsize Total \\ Cost = [C_c * \\frac{Q}{2}] + [\\frac{D}{Q} * C_o] ';
    const numberoforders = '\\frac{D}{Q} = \\text{Number of Orders per Year}';
    const differentiatedtotalcost = '\\Large \\frac{d(Total \\ Cost)}{dQ} = - \\frac{D C_o}{Q^2} + \\frac{Q C_h}{2}';
    const eoq = '\\large Q^{*} = \\sqrt{ \\frac{2DC_o}{C_h}}';
    const totalcostoptimal = 'Total \\ Cost = \\sqrt{2 D C_h C_o}';
    

    onMounted(() => {
      if (mathContainer1.value) {
        katex.render(annualDemand, mathContainer1.value, {
          throwOnError: false
        });
      }
      if (mathContainer2.value) {
        katex.render(orderingCost, mathContainer2.value, {
          throwOnError: false
        });
      }
      if (mathContainer3.value) {
        katex.render(holdingCost, mathContainer3.value, {
          throwOnError: false
        });
      }
      if (mathContainer4.value) {
        katex.render(costperunit, mathContainer4.value, {
          throwOnError: false
        });
      }
      if (mathContainer5.value) {
        katex.render(Q, mathContainer5.value, {
          throwOnError: false
        });
      }
      if (mathContainer6.value) {
        katex.render(totalCost, mathContainer6.value, {
          throwOnError: false
        });
      }
      if (mathContainer7.value) {
        katex.render(numberoforders, mathContainer7.value, {
          throwOnError: false
        });
      }
      if (mathContainer8.value) {
        katex.render(differentiatedtotalcost, mathContainer8.value, {
          throwOnError: false
        });
      }
      if (mathContainer9.value) {
        katex.render(eoq, mathContainer9.value, {
          throwOnError: false
        });
      }
      if (mathContainer10.value) {
        katex.render(totalcostoptimal, mathContainer10.value, {
          throwOnError: false
        });
      }
    });
    
    useHead({
      title: 'Economic Order Quantity (EOQ) | Industrial Engineering | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: '',
        },
        {
          name: 'keywords',
          content: 'Economic Order Quantity (EOQ), EOQ Model, Industrial Engineering, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'Economic Order Quantity (EOQ) | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: '',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://themechanicalgenuis.com/industrial-engineering/economic-order-quantity',  // Canonical URL for the homepage
        }
      ]
    });

    return{
      mathContainer1,
      mathContainer2,
      mathContainer3,
      mathContainer4,
      mathContainer5,
      mathContainer6,
      mathContainer7,
      mathContainer8,
      mathContainer9,
      mathContainer10 
    }
  },
}
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    
    
    li, p, ul {
      font-size: 1em;
      line-height: 1.75;
      letter-spacing: 0.025em;
      font-family: serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    .equations{
      text-align: center;
      padding-top: 1em;
      padding-bottom: 1em;
    }

    .content-container h3{
      font-size: 1.1em;
      color: #000d47;
      font-weight: 200;
      margin: 0;
    }

    .diagram{
      padding-top: 2em;
      padding-bottom: 2em;
    }


    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .content-container ol{
      padding-inline-start: 1em;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }

    .diagram img{
      width: 100%;
    }


  }
    </style>
    