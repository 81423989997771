// List of Topics in section Machine Design

export default [
    { id: 'machine-design/factor-of-safety', name: 'What is the factor of safety?'},
    { id: 'machine-design/failure-theory', name:'What are different theories of failure?'},
    { id: 'machine-design/endurance-limit', name:'What is Endurance Limit and factors affecting it?'},
    { id:'machine-design/endurance-vs-fatigue', name:'Difference between Endurance Strength and Fatigue Strength'},
    { id: 'machine-design/stress-concentration', name:'What is Stress Concentration?'},
    { id: 'machine-design/advantages-disadvantages-v-belts', name:'What are advantages and disadvantages of V-belt?'},
    { id: 'machine-design/advantages-disadvantages-gear-drive', name:'What are advantages and disadvantages of Gear drive?'},
    { id: 'machine-design/flywheel-vs-governer', name:'What is the difference between functioning of Flywheel and Governer?'},
    { id: '', name: 'Let us know other topics you want included in Machine Design'},
    // Add more topics here
  ];