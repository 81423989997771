<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/machine-design/endurance-vs-fatigue'"
      :headline="'Difference between Endurance Strength and Fatigue Strength | The Mechanical Genius'"
      :description="'Endurance strength is the maximum stress a material can withstand for an infinite number of cycles while Fatigue strength is the maximum stress a material can withstand for 10^6 cycles.'"
      :keywords="['Endurance Strength', 'Machine Design', 'Mechanical Engineering']"
      :topic="'Machine Design'"
      :topiclink="'https://themechanicalgenuis.com/machine-design'"
      :question="'Difference between Endurance Strength and Fatigue Strength?'"
      :answer="'Endurance strength is the maximum stress a material can withstand for an infinite number of cycles while Fatigue strength is the maximum stress a material can withstand for 10^6 cycles.'"
    />
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Machine Design</h3>
              </div>
  
  
              <div class="article-title">
                <h1>Difference between Endurance Strength and Fatigue Strength?</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
            <section class="content-container">
                <table>
                    <thead>
                    <tr>
                        <th>S. No.</th>
                        <th>Endurance Strength</th>
                        <th>Fatigue Strength</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>1</td>
                        <td>Maximum stress a material can withstand for an infinite number of cycles without failing.</td>
                        <td>Maximum stress a material can withstand for 10<sup>6</sup> before failing.</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>It is denoted by S<sub>e</sub></td>
                        <td>It is denoted by S<sub>f</sub></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Endurance strength is estimated based on the ultimate tensile strength.</td>
                        <td>Fatigue strength is estimated from S-N curve through experimental testing</td>
                    </tr>
                    </tbody>
                </table>
            </section>
  
        </div>
      </div>
  
      
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'EnduranceVsFatigue',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    useHead({
      title: 'Difference between Endurance Strength and Fatigue Strength | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'Endurance strength is the maximum stress a material can withstand for an infinite number of cycles while Fatigue strength is the maximum stress a material can withstand for 10^6 cycles.',
        },
        {
          name: 'keywords',
          content: 'Endurance vs Fatigue, Fatigue Strength, Endurance Strength, Machine Design, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'Difference between Endurance Strength and Fatigue Strength | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'Endurance strength is the maximum stress a material can withstand for an infinite number of cycles while Fatigue strength is the maximum stress a material can withstand for 10^6 cycles.',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });
  },
}
</script>
    
<style scoped>
.article-layout{
  margin: 0 5em;
}

.heading-container{
  width: 100%;
  height: 20em;

}

.header-spacer{
  width: 100%;
  height: 10em;
}

.headline{
  padding: 0 0em;
}

.content-container{
  padding-bottom: 1em;
}

.article-topic h3{
  font-size: 1em;
  color: #309c99;
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-weight: 200;

}

.article-title h1{
  font-size: 2.5em;
  color: #000;
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-weight: 100;
}

.article-content-in-full{
  width: 75%;
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

.article-content-in-full h2{
  font-weight: 200;
  font-size: 1.5em;
  color: #000d47;
  letter-spacing: 0.01em;
 }



p, ul {
  font-size: 1.1em;
  line-height: 1.75;
  text-align: justify;
  letter-spacing: 0.025em;
  font-family: serif;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

}


table, th, td {
  border: 1px solid black;
  font-family: serif;
  font-weight: 100;
  font-size: 1em;
  line-height: 1.75;
  letter-spacing: 0.025em;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}


@media (max-width: 768px) {
  .article-layout{
    padding: 0 1em;
    margin: 0;
  }

  .heading-container{
  width: 100%;
  height: 20em;
  }

  .header-spacer{
    width: 100%;
    height: 5em;
  }

  .article-title h1{
  font-size: 2em;
  color: #000;
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-weight: 100;
  }

  .article-content-in-full{
    width: 100%;
  }

  th, td {
  padding: 6px;
  text-align: left;
  }
  p, ul, li {
font-size: 0.9em;
line-height: 1.75;
font-family: serif;
}


}
</style>
