<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/thermodynamics/intensive-vs-extensive-properties'"
      :headline="'Difference between Intensive and Extensive Properties | The Mechanical Genius'"
      :description="'Intensive properties are independent of the amount of matter or the size of the system, while extensive properties are dependent on the amount of matter or the size of the system.'"
      :keywords="['Intensive and Extensive Properties', 'Thermodynamics', 'Mechanical Engineering']"
      :topic="'Thermodynamics'"
      :topiclink="'https://themechanicalgenuis.com/thermodynamics'"
      :question="'Difference between Intensive and Extensive Properties'"
      :answer="'Intensive properties are independent of size of the system while extensive properties are dependent on size of system.'"
    />
  
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Thermodynamics</h3>
              </div>
  
  
              <div class="article-title">
                <h1>Difference between Intensive and Extensive Properties</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
            <section class="content-container">
                <table>
                    <thead>
                    <tr>
                        <th>S. No.</th>
                        <th>Intensive Property</th>
                        <th>Extensive Property</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>1</td>
                        <td>Properties that do not depend on the amount of matter or the size of the system</td>
                        <td>Properties that are dependent on the amount of matter or the size of the system</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Properties remain the same regardless of the system’s scale</td>
                        <td>Properties change when the size of the system changes</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Ex. Temperature, Pressure, Density, Viscosity, Boiling Point and Melting Point</td>
                        <td>Ex. Mass, Volume, Internal Energy, Enthalpy and Entropy</td>
                    </tr>
                    </tbody>
                </table>
            </section>
        </div>
    
    </div>
  
      
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';
export default {
  name: 'IntensivevsExtensiveProperties',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
  useHead({
    title: 'Difference between Intensive and Extensive Properties | The Mechanical Genius',
    meta: [
      {
        name: 'description',
        content: 'Intensive properties are independent of the amount of matter or the size of the system, while extensive properties are dependent on the amount of matter or the size of the system.',
      },
      {
        name: 'keywords',
        content: 'Intensive and Extensive Properties, Thermodynamics, Mechanical Engineering',
      },
      {
        property: 'og:title',
        content: 'Difference between Intensive and Extensive Properties | The Mechanical Genius',
      },
      {
        property: 'og:description',
        content: 'Intensive properties are independent of the amount of matter or the size of the system, while extensive properties are dependent on the amount of matter or the size of the system.',
      },
      {
        property: 'og:type',
        content: 'article',
      }
    ],
  });
},
};
</script>

<style scoped>
.article-layout{
  margin: 0 5em;
}

.heading-container{
  width: 100%;
  height: 20em;

}

.header-spacer{
  width: 100%;
  height: 10em;
}

.headline{
  padding: 0 0em;
}

.content-container{
  padding-bottom: 1em;
}

.article-topic h3{
  font-size: 1em;
  color: #309c99;
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-weight: 200;

}

.article-title h1{
  font-size: 2.5em;
  color: #000;
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-weight: 100;
}

.article-content-in-full{
  width: 75%;
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

.article-content-in-full h2{
  font-weight: 200;
  font-size: 1.5em;
  color: #000d47;
  letter-spacing: 0.01em;
  }



p, ul {
  font-size: 1.1em;
  line-height: 1.75;
  text-align: justify;
  letter-spacing: 0.025em;
  font-family: serif;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

}


table, th, td {
  border: 1px solid black;
  font-family: serif;
  font-weight: 100;
  font-size: 1em;
  line-height: 1.75;
  letter-spacing: 0.025em;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}


@media (max-width: 768px) {
  .article-layout{
    padding: 0 1em;
    margin: 0;
  }

  .heading-container{
  width: 100%;
  height: 20em;
  }

  .header-spacer{
    width: 100%;
    height: 5em;
  }

  .article-title h1{
  font-size: 2em;
  color: #000;
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-weight: 100;
  }

  .article-content-in-full{
    width: 100%;
  }

  th, td {
  padding: 6px;
  text-align: left;
  }

  p, ul, li {
font-size: 0.9em;
line-height: 1.75;
font-family: serif;
}


}
</style>
    