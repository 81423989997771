<template>
  <Header_bar />
  <SeoComponent
      :link="'https://themechanicalgenuis.com/thermodynamics/zeroth-law-of-thermodynamics'"
      :headline="'What is Zeroth Law of Thermodynamics | The Mechanical Genius'"
      :description="'Zeroth Law of Thermodynamics state that if two thermodynamic system are both in thermal equilibrium with third system, then these two system are in thermal equilibrium with each other.'"
      :keywords="['Zeroth Law of Thermodynamics', 'Laws of Thermodynamics', 'Mechanical Engineering']"
      :topic="'Thermodynamics'"
      :topiclink="'https://themechanicalgenuis.com/thermodynamics'"
      :question="'What is Zeroth Law of Thermodynamics?'"
      :answer="'Zeroth Law of Thermodynamics state that if two thermodynamic system are both in thermal equilibrium with third system, then these two system are in thermal equilibrium with each other.'"
    />
    <div class="article-layout">

      <div class="heading-container">
        <div class="header-spacer"></div>
        
          <div class="headline">
            <div class="article-topic">
              <h3>Thermodynamics</h3>
            </div>


            <div class="article-title">
              <h1>What is Zeroth Law of Thermodynamics?</h1>
            </div>
          </div>
      </div>

      <div class="article-content-in-full">
        <section class="content-container">
          <h2>Definition</h2>
          <p>Zeroth Law of Thermodynamics state that if two thermodynamic system are both in thermal equilibrium with third system, then these two system are in thermal equilibrium with each other.</p>
          <p>OR</p>
          <p>If a body C, be in thermal equilibrium with two other bodies, A and B, then A and B are in thermal equilibrium with one another.</p>

          <p>This law is crucial because it allows us to define temperature in a consistent and meaningful way. Without the Zeroth Law, the concept of temperature would be ambiguous, as we wouldn’t be able to assert that two systems are at the same temperature without a third reference system.</p>
        </section>

        <section class="content-container">
        <h2>Equation</h2>
        <p>The mathematical expression for the zeroth Law of thermodynamics is:</p>

        <p>If  T<sub>A</sub>  is the temperature of system A, T<sub>B</sub>  is the temperature of system B, and T<sub>C</sub> is the temperature of system C. And it is given that T<sub>A</sub> = T<sub>C</sub> and also T<sub>B</sub> = T<sub>C</sub></p>
        <p>Then according to Zeroth Law of Thermodynamics - </p>

        <p><strong>T<sub>A</sub> = T<sub>B</sub></strong></p>
        <div class="diagram">
          <img src='../../assets/images/thermodynamics_img/zeroth_law_of_thermodynamics.png' alt='Zeroth Law of Thermodynamics'/>
        </div>
        
        </section>
      
      <section class="content-container">
        <h2>Example</h2>
        <p>Consider three systems: A, B, and C.</p>
        <ol>
          <li>System A: A cup of hot coffee</li>
          <li>System B: A thermometer</li>
          <li>System C: Another cup of hot coffee, identical to the first one</li>
        </ol>

        <p>If we place the thermometer (System B) in the first cup of coffee (System A) and wait for thermal equilibrium to be reached, the thermometer will read a certain temperature, say T<sub>A</sub>. Next, if we place the same thermometer (System B) in the second cup of coffee (System C) and wait for thermal equilibrium to be reached, the thermometer will read the same temperature, say  T<sub>C</sub>.</p>
        <p>According to the Zeroth Law of Thermodynamics, since the thermometer reads the same temperature when in equilibrium with both cups of coffee, the two cups of coffee (Systems A and C) must be in thermal equilibrium with each other ( T<sub>A</sub> = T<sub>C</sub> ).</p>
      </section>

      </div>
    </div>

    
  </template>
  
  <script>
  import SeoComponent from '@/components/ArticleSeo.vue';
  import Header_bar from '@/layouts/header.vue';
  import { useHead } from '@vueuse/head';
  export default {
    name: 'ZerothLawOfThermodynamics',
    components:{
      Header_bar,
      SeoComponent
    },
    setup() {
    useHead({
      title: 'What is Zeroth Law of Thermodynamics | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'Zeroth Law of Thermodynamics state that if two thermodynamic system are both in thermal equilibrium with third system, then these two system are in thermal equilibrium with each other.',
        },
        {
          name: 'keywords',
          content: 'Zeroth Law of Thermodynamics, Laws of Thermodynamics, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'What is Zeroth Law of Thermodynamics | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'Zeroth Law of Thermodynamics state that if two thermodynamic system are both in thermal equilibrium with third system, then these two system are in thermal equilibrium with each other.',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });
  },
  };
  </script>
  
  <style scoped>
  .article-layout{
    margin: 0 5em;
  }

  .heading-container{
    width: 100%;
    height: 20em;

  }

  .header-spacer{
    width: 100%;
    height: 10em;
  }

  .headline{
    padding: 0 0em;
  }

  .content-container{
    padding-bottom: 1em;
  }

  .article-topic h3{
    font-size: 1em;
    color: #309c99;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 200;

  }

  .article-title h1{
    font-size: 2.5em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
  }

  .article-content-in-full{
    width: 75%;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  }

  .article-content-in-full h2{
    font-weight: 200;
    font-size: 1.5em;
    color: #000d47;
    letter-spacing: 0.01em;
   }
  
  .content-container ol li{
      padding-bottom: 1em;
      
    }
  
  
  p, ul, li {
    font-size: 1.1em;
    line-height: 1.75;
    text-align: justify;
    letter-spacing: 0.025em;
    font-family: serif;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  .diagram{
      padding: 2em;
    }
  
  @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }


    .content-container ol li{
      padding-bottom: 1em;
      font-family: serif;
      font-weight: 100;
    }

    .diagram img{
      width: 100%;
    }

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
  }

  }
  </style>
  