<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/thermodynamics/quasi-static-process'"
      :headline="'What is Quasi Static Process | The Mechanical Genius'"
      :description="'A quasi-static process (also known as a quasi-equilibrium process) is an ideal thermodynamic process that happens infinitely slowly, so that the system remains in equilibrium at all times.'"
      :keywords="['Quasi Static Process', 'Quasi-Equilibrium Process', 'Thermodynamics', 'Mechanical Engineering']"
      :topic="'Thermodynamics'"
      :topiclink="'https://themechanicalgenuis.com/thermodynamics'"
      :question="'What is Quasi Static Process?'"
      :answer="'A quasi-static process (also known as a quasi-equilibrium process) is an ideal thermodynamic process that happens infinitely slowly, so that the system remains in equilibrium at all times.'"
    />
  
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Thermodynamics</h3>
              </div>
  
  
              <div class="article-title">
                <h1>What is Quasi Static Process?</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
            <section class="content-container">
                <h2>Definition</h2>
                <p>A quasi-static process (also known as a quasi-equilibrium process) is an ideal thermodynamic process that happens infinitely slowly, so that the system remains in equilibrium at all times.</p>
            </section>
  
            <section class="content-container">
            <h2>Mathematical Expression</h2>
            <p>Consider an ideal gas undergoing a quasi-static compression</p>
            <ul>
                <li>Initial state:  P<sub>1</sub>, V<sub>1</sub>, T<sub>1</sub></li>
                <li>Final state:  P<sub>2</sub>, V<sub>2</sub>, T<sub>2</sub> </li>
            </ul>
            <p>The gas remains in near-equilibrium states throughout the process. In this case, we can apply the ideal gas law (or other state equations) to describe the gas properties at each infinitesimally small step <strong>PV = nRT</strong></p>
            <div class="diagram">
                <img src="../../assets/images/thermodynamics_img/quasi-static-process.gif" alt="Quasi Static Process"/>
            </div>
            
            <h2>PV work in various quasi-static-process</h2>
            <ol>
                <li>Constant pressure
                    <p>W<sub>1-2</sub> = &int; P dV = P(V<sub>2</sub> - V<sub>1</sub>)</p>
                </li>
                <li>Constant volume
                    <p>W<sub>1-2</sub> = &int; P dV = 0</p>
                </li>
                <li>Constant temperature
                    <p>W<sub>1-2</sub> = P<sub>1</sub>V<sub>1</sub> ln (V<sub>2</sub>/V<sub>1</sub>)</p>
                </li>
                <li>Polytropic processes : 
                    <p>W<sub>1-2</sub> = (P<sub>1</sub>V<sub>1</sub> - P<sub>2</sub>V<sub>2</sub>)/(n - 1)</p>
                </li>
            </ol>
            </section>

            <section class="content-container">
            <h2>Detailed Explaination</h2>
            <p>Quasi-static processes are idealized processes that help in understanding and analyzing thermodynamic systems by approximating real processes that occur very slowly. While no real process can be truly quasi-static, this concept is crucial for simplifying the study of thermodynamics and deriving fundamental principles such as those involved in the Carnot cycle or calculating the efficiency of heat engines.</p>
            
            </section>
        

  
        </div>
      </div>
  
      
    </template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'QuasiStaticProcess',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    useHead({
      title: 'What is Quasi Static Process | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'A quasi-static process (also known as a quasi-equilibrium process) is an ideal thermodynamic process that happens infinitely slowly, so that the system remains in equilibrium at all times.',
        },
        {
          name: 'keywords',
          content: ' Quasi Static Process, Quasi-Equilibrium Process, Thermodynamics, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'What is Quasi Static Process | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'A quasi-static process (also known as a quasi-equilibrium process) is an ideal thermodynamic process that happens infinitely slowly, so that the system remains in equilibrium at all times.',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });
  },
};
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }

     .diagram{
      padding: 2em;
    }
    
    
    
    p, ul {
      font-size: 1.1em;
      line-height: 1.75;
      text-align: justify;
      letter-spacing: 0.025em;
      font-family: Merriweather, Georgia, serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .diagram img{
      width: 100%;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }

  }
    </style>
    