<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/fluid-mechanics/max-hydraulic-efficiency-pelton-wheel'"
      :headline="'Maximum hydraulic efficiency derivation for pelton turbine | The Mechanical Genius'"
      :description="'Hydraulic efficiency of pelton turbine is maximum when velocity of wheel is half of jet velocity'"
      :keywords="['Maximum Hydraulic Efficiency', 'Pelton Turbine', 'Fluid Mechanics', 'Mechanical Engineering']"
      :topic="'Fluid Mechanics'"
      :topiclink="'https://themechanicalgenuis.com/fluid-mechanics'"
      :question="'Maximum hydraulic efficiency derivation for pelton turbine'"
      :answer="'Hydraulic efficiency of pelton turbine is maximum when velocity of wheel is half of jet velocity'"
    />
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Fluid Mechanics</h3>
              </div>

              <div class="article-title">
                <h1>Maximum hydraulic efficiency derivation for pelton turbine</h1>
              </div>
            </div>
        </div>

        
  
        <div class="article-content-in-full">

          <section class="content-container">
            <p>Hydraulic Efficiency for the pelton wheel is given by, </p>
            <div class='equations' ref="mathContainer1"></div>
            <p>Efficiency will be maximum for given value of V<sub>1</sub> when, </p>
            <div class="equations" ref="mathContainer2"></div>
            <p>The above equation states that hydraulic efficiency of pelton wheel is maximum when velocity of wheel is half the velocity of jet at inlet. So maximum efficiency equation will be,</p>
            <div class="equations" ref="mathContainer3"></div>
            <p>If friction factor, K=1 (i.e. assuming no friction), we have</p>
            <div class="equations" ref="mathContainer4"></div>
          
          </section>
        </div>
      </div>
  
      
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';
import { onMounted, ref } from 'vue';
import katex from 'katex';
import 'katex/dist/katex.min.css';



export default {
  name: 'MaxHydraulicEfficiencyPeltonWheel',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    const mathContainer1 = ref(null);
    const mathContainer2 = ref(null);
    const mathContainer3 = ref(null);
    const mathContainer4 = ref(null);

    const hydraulicEfficiency = `\\large
    \\eta_h = \\frac{2[(V_1 - u) (1 + K\\cos{\\phi})]u}{V_1^2}\\\\`;

    const maxCondition = `
    \\frac{d\\eta_h}{du} = 0 \\\\[10pt]
    \\frac{d}{du} \\left(\\frac{2[(V_1 - u) (1 + K\\cos{\\phi})]u}{V_1^2}\\right) = 0 \\\\[10pt]
    \\frac{2(1 + K\\cos{\\phi})}{{V_1}^2} \\times \\frac{d}{du} (V_1u - u^2) = 0 \\\\[10pt]
    \\frac{d}{du} (V_1u - u^2) = V_1 - 2u = 0 \\\\[10pt]
    u = \\frac{V_1}{2}
    `;

    const maxEffEquation = `\\large
    ({\\eta_h})_{max} = \\frac{2[(V_1 - \\frac{V_1}{2}) (1 + K\\cos{\\phi})] \\frac{V_1}{2}}{V_1^2} \\\\[10pt]
    ({\\eta_h})_{max} = \\frac{(1+K\\cos{\\phi})}{2} \\\\[10pt]`;

    const finalequation = `\\large
    ({\\eta_h})_{max} = \\frac{1+\\cos{\\phi}}{2} \\\\[10pt]`;





    onMounted(() => {
      if (mathContainer1.value) {
        katex.render(hydraulicEfficiency, mathContainer1.value, {
          throwOnError: false
        });
      }
      if (mathContainer2.value) {
        katex.render(maxCondition, mathContainer2.value, {
          throwOnError: false
        });
      }
      if (mathContainer3.value) {
        katex.render(maxEffEquation, mathContainer3.value, {
          throwOnError: false
        });
      }
      if (mathContainer4.value) {
        katex.render(finalequation, mathContainer4.value, {
          throwOnError: false
        });
      }
    });

    useHead({
      title: 'Maximum hydraulic efficiency derivation for pelton turbine | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'Hydraulic efficiency of pelton turbine is maximum when velocity of wheel is half of jet velocity',
        },
        {
          name: 'keywords',
          content: 'Maximum hydraulic efficiency, Efficiency of Pelton Turbine, Pelton Wheel Derivation, Fluid Mechanics, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'Maximum hydraulic efficiency derivation for pelton turbine | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'Hydraulic efficiency of pelton turbine is maximum when velocity of wheel is half of jet velocity',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });
    return {
      mathContainer1,
      mathContainer2,
      mathContainer3,
      mathContainer4,
    };
    
  },

}
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    .content-container h3{
      font-size: 1.1em;
      color: #000d47;
      font-weight: 200;
    }
    
    
    li, p, ul {
      font-size: 1em;
      line-height: 1.75;
      letter-spacing: 0.025em;
      font-family: serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    .equations{
      text-align: center;
    }

    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .content-container ol{
      padding-inline-start: 1em;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }


  }
    </style>
    