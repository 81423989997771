<template>
    <Header_bar />
    <teleport to="head">
      <component :is="'script'" type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "Article",
          "headline": "What is Detonation? What are the causes and effects of detonation | The Mechanical Genius",
          "description": "Detonation is an uncontrolled combustion of air-fuel mixture in internal combustion engines.",
          "author": {
            "@type": "Organization",
            "name": "The Mechanical Genius"
          },
          "publisher": {
            "@type": "Organization",
            "name": "The Mechanical Genius"
          },
          "url": "https://themechanicalgenuis.com/ic-engine/detonation",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://themechanicalgenuis.com"
          }
        }
      </component>
    </teleport>
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>IC Engine</h3>
              </div>
  
  
              <div class="article-title">
                <h1>What is Detonation? What are the factors on which detonation depends?</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
          <section class="content-container">
            <h2>Definition</h2>
            <p>Detonation is an uncontrolled combustion of air-fuel mixture in internal combustion engines. It happens when the air-fuel mixture in the combustion chamber ignites prematurely and explosively, rather than burning smoothly. This results in a sharp knocking noise and can cause significant damage to the engine.</p>
          </section>
  
          <section class="content-container">
            <h2>Factors on which Detonation depends</h2>
            <ol>
              <li><strong>Compression Ratio</strong></li><p>Higher the compression ratio higher the chances of detonation. High compression ratio increases the temperature and pressure of the air-fuel mixture.</p>
              <li><strong>Fuel Quality</strong></li><p>Lower-octane fuels are more prone to detonation. High-octane fuels resist premature ignition.</p>
              <li><strong>Air-Fuel Mixture</strong></li><p>Lean mixtures (too much air and not enough fuel) are more likely to detonate than rich mixtures.</p>
              <li><strong>Engine Temperature</strong></li><p>High engine temperatures can lead to detonation, as the increased heat can cause the air-fuel mixture to ignite prematurely.</p>
              <li><strong>Ignition Timing</strong></li><p>If the air-fuel mixture ignited too early (advanced timing), it lead to detonation.</p>
            </ol>
          </section>
        
        <section class="content-container">
          <h2>Effects of Detonation</h2>
          <ol>
            <li><strong>Knocking</strong></li><p>A sharp, metallic knocking or pinging noise is a common symptom of detonation.</p>
            <li><strong>Loss of Power</strong></li><p>Due to inefficient combustion, detonation result in loss of engine power.</p>
            <li><strong>Overheating</strong></li><p>The increased temperatures from detonation can lead to engine overheating.</p>
            <li><strong>Damage to Engine</strong></li><p>Severe detonation can cause physical damage to the pistons, such as pitting, cracking, or even holes.</p>
            <li><strong>Reduced Engine Life</strong></li><p>Prolonged detonation can significantly reduce the lifespan of an engine due to the added stress and damage to internal components.</p>
          </ol>
          
        </section>
  
        </div>
      </div>
  
      
</template>
    
<script>
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'Detonation_',
  components:{
    Header_bar
  },
  setup() {
    useHead({
      title: 'What is Detonation? What are the causes and effects of detonation | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'Detonation is an uncontrolled combustion of air-fuel mixture in internal combustion engines.',
        },
        {
          name: 'keywords',
          content: 'Detonation, Causes and Effects of Detonation, Internal Combustion Engine, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'What is Detonation? What are the causes and effects of detonation | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'Detonation is an uncontrolled combustion of air-fuel mixture in internal combustion engines.',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });
  },
}
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    
    
    li, p, ul {
      font-size: 1.1em;
      line-height: 1.75;
      text-align: justify;
      letter-spacing: 0.025em;
      font-family: serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .content-container ol{
      padding-inline-start: 1em;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }


  }
    </style>
    