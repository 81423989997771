import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue';
import FirstLawOfThermodynamics from '../pages/thermodynamics/FirstLawOfThermodynamics.vue';
import SecondLawOfThermodynamics from '../pages/thermodynamics/SecondLawOfThermodynamics.vue';
import Thermodynamics from '../pages/thermodynamics/Thermodynamics.vue'
import Detonation from '../pages/ic_engine/Detonation.vue'
import ICEngine from '../pages/ic_engine/ICEngine.vue'
import Heat_Transfer from '../pages/heat_transfer/HeatTransfer.vue'
import IgnitionTiming from '../pages/ic_engine/IgnitionTiming.vue';
import Scavenging from '../pages/ic_engine/Scavenging.vue';
import FluidMechanics from '../pages/fluid_mechanics/FluidMechanics.vue';
import PeltonVsFrancisTurbine from '../pages/fluid_mechanics/PeltonvsFrancisTurbine.vue'
import ImpulseVsReactionTurbine from '../pages/fluid_mechanics/ImpulsevsReactionTurbine.vue'
import PeltonvsFrancisvsKaplanTurbine from '../pages/fluid_mechanics/PeltonvsFrancisvsKaplanTurbine.vue'
import ADFrancisvsPeltonTurbine from '../pages/fluid_mechanics/AdvantageandDisadvantageFrancisoverPeltonTurbine.vue'
import ZerothLawOfThermodynamics from '../pages/thermodynamics/ZerothLawOfThermodynamics.vue'
import IntensivevsExtensiveProperties from '../pages/thermodynamics/IntensivevsExtensiveProperties.vue'
import QuasiStaticProcess from '../pages/thermodynamics/QuasiStaticProcess.vue'
import ModesofHeatTransfer from '../pages/heat_transfer/ModesofHeatTransfer.vue';
import ThermalConductivity from '../pages/heat_transfer/ThermalConductivity.vue';
import EffectOfTemperatureOnThermalConductivity from '../pages/heat_transfer/EffectofTemperatureonThermalConductivity.vue';
import CriticalThicknessOfInsulation from '../pages/heat_transfer/CriticalThicknessofInsulation.vue';
import IndustrialEngineering from '../pages/industrial_engineering/IndustrialEngineering.vue';
import EconomicOrderQuantity from '../pages/industrial_engineering/EconomicOrderQuantity.vue';
import MachineDesign from '../pages/machine_design/MachineDesign.vue';
import Topics from '../pages/Topics.vue';
import FailureTheory from '../pages/machine_design/FailureTheory.vue';
import FactorOfSafety from '../pages/machine_design/FactorOfSafety.vue';
import EnduranceVsFatigue from '../pages/machine_design/EnduranceVsFatigue.vue';
import EnduranceLimit from '../pages/machine_design/EnduranceLimit.vue';
import StressConcentration from '../pages/machine_design/StressConcentration.vue';
import PeltonWheelDerivation from '../pages/fluid_mechanics/PeltonWheelDerivation.vue';
import MaxHydraulicEfficiencyPeltonWheel from '../pages/fluid_mechanics/MaxHydraulicEfficiencyPeltonWheel.vue';
import AdvantagesDisadvantagesVBelts from '../pages/machine_design/AdvantageDisadvantageVBelts.vue';
import AdvantagesDisadvantagesGearDrive from '../pages/machine_design/AdvantageDisadvantageGearDrive.vue';
import FlywheelVsGoverner from '../pages/machine_design/FlywheelVsGoverner.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/thermodynamics',
    name: 'Thermodynamics',
    component: Thermodynamics,

  },
  {
    path: '/ic-engine',
    name: 'ICEngine',
    component: ICEngine,
  },
  {
    path: '/heat-transfer',
    name: 'HeatTransfer',
    component: Heat_Transfer,
  },
  {
    path: '/fluid-mechanics',
    name: 'FluidMechanics',
    component: FluidMechanics,
  },
  {
    path: '/thermodynamics/first-law-of-thermodynamics',
    name: 'FirstLawOfThermodynamics',
    component: FirstLawOfThermodynamics,
  },
  {
    path: '/thermodynamics/second-law-of-thermodynamics',
    name: 'SecondLawOfThermodynamics',
    component: SecondLawOfThermodynamics,
  },
  {
    path: '/ic-engine/detonation',
    name: 'Detonation',
    component: Detonation,
  },
  {
    path: '/ic-engine/ignition-timing',
    name: 'IgnitionTiming',
    component: IgnitionTiming,
  },
  {
    path: '/ic-engine/scavenging',
    name: 'Scavenging',
    component: Scavenging,

  },
  {
    path: '/fluid-mechanics/pelton-vs-francis-turbine',
    name: 'PeltonVsFrancisTurbine',
    component: PeltonVsFrancisTurbine,

  },
  {
    path: '/fluid-mechanics/impulse-vs-reaction-turbine',
    name: 'ImpulseVsReactionTurbine',
    component: ImpulseVsReactionTurbine,
  },
  {
    path: '/fluid-mechanics/pelton-vs-francis-vs-kaplan-turbine',
    name: 'PeltonvsFrancisvsKaplanTurbine',
    component: PeltonvsFrancisvsKaplanTurbine,
  },
  {
    path: '/fluid-mechanics/advantage-drawback-francis-over-pelton',
    name: 'ADFrancisvsPeltonTurbine',
    component: ADFrancisvsPeltonTurbine,
  },
  {
    path: '/thermodynamics/zeroth-law-of-thermodynamics',
    name: 'ZerothLawOfThermodynamics',
    component: ZerothLawOfThermodynamics,
  },
  {
    path: '/thermodynamics/intensive-vs-extensive-properties',
    name: 'IntensivevsExtensiveProperties',
    component: IntensivevsExtensiveProperties,
  },
  {
    path: '/thermodynamics/quasi-static-process',
    name: 'QuasiStaticProcess',
    component: QuasiStaticProcess,

  },
  {
    path: '/heat-transfer/modes-of-heat-transfer',
    name: 'ModesOfHeatTransfer',
    component: ModesofHeatTransfer,
  },
  {
    path: '/heat-transfer/thermal-conductivity',
    name: 'ThermalConductivity',
    component: ThermalConductivity,
  },
  {
    path: '/heat-transfer/effect-of-temperature-on-thermal-conductivity',
    name: 'EffectOfTemperatureOnThermalConductivity',
    component: EffectOfTemperatureOnThermalConductivity,
  },
  {
    path: '/heat-transfer/critical-thickness-of-insulation',
    name: 'CriticalThicknessOfInsulation',
    component: CriticalThicknessOfInsulation,
  },
  {
    path: '/industrial-engineering',
    name: 'IndustrialEngineering',
    component: IndustrialEngineering,
  },
  {
    path: '/industrial-engineering/economic-order-quantity',
    name: 'EconomicOrderQuantity',
    component: EconomicOrderQuantity,
  },
  {
    path: '/machine-design',
    name: 'MachineDesign',
    component: MachineDesign
  },
  {
    path: '/others',
    name: Topics,
    component: Topics

  },
  {
    path:'/machine-design/failure-theory',
    name:'FailureTheory',
    component: FailureTheory,
  },
  {
    path: '/machine-design/factor-of-safety',
    name: 'FactorOfSafety',
    component: FactorOfSafety,
  },
  {
    path: '/machine-design/endurance-vs-fatigue',
    name: 'EnduranceVsFatigue',
    component: EnduranceVsFatigue,
  },
  {
    path: '/machine-design/endurance-limit',
    name: 'EnduranceLimit',
    component: EnduranceLimit,
  },
  {
    path: '/machine-design/stress-concentration',
    name: 'StressConcentration',
    component: StressConcentration,
  },
  {
    path: '/fluid-mechanics/pelton-wheel-derivation',
    name: 'PeltonWheelDerivation',
    component: PeltonWheelDerivation,
  },
  {
    path: '/fluid-mechanics/max-hydraulic-efficiency-pelton-wheel',
    name: 'MaxHydraulicEfficiencyPeltonWheel',
    component: MaxHydraulicEfficiencyPeltonWheel,
  },
  {
    path: '/machine-design/advantages-disadvantages-v-belts',
    name: 'AdvantagesDisadvantagesVBelts',
    component: AdvantagesDisadvantagesVBelts,
  },
  {
    path: '/machine-design/advantages-disadvantages-gear-drive',
    name: 'AdvantagesDisadvantagesGearDrive',
    component: AdvantagesDisadvantagesGearDrive,
  },
  {
    path: '/machine-design/flywheel-vs-governer',
    name: 'FlywheelVsGoverner',
    component: FlywheelVsGoverner,
  }
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
