<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/heat-transfer/critical-thickness-of-insulation'"
      :headline="'Critical Thickness of Insulation | The Mechanical Genius'"
      :description="'The critical thickness of insulation refers to the thickness of insulation at which the heat loss from a surface is minimized. Beyond this critical thickness, any further increase in insulation thickness results in an increase in heat loss.'"
      :keywords="['Critical Thickness of Insulation', 'Critical Radii', 'Heat Transfer', 'Mechanical Engineering']"
      :topic="'Heat Transfer'"
      :topiclink="'https://themechanicalgenuis.com/heat-transfer'"
      :question="'What is critical thickness of Insulation?'"
      :answer="'The critical thickness of insulation refers to the thickness of insulation at which the heat loss from a surface is minimized. Beyond this critical thickness, any further increase in insulation thickness results in an increase in heat loss.'"
    />
    
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Heat Transfer</h3>
              </div>
  
  
              <div class="article-title">
                <h1>What is critical thickness of Insulation?</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
          <section class="content-container">
            <h2>Definition</h2>
            <p>The critical thickness of insulation refers to the thickness of insulation at which the heat loss from a surface is minimized. Beyond this critical thickness, any further increase in insulation thickness results in an increase in heat loss.</p>
          </section>
  
          <section class="content-container">
            <h2>Derivation of Critical Thickness/Radii of Insulation</h2>
            <h3>Assumptions :</h3>
            <ol>
              <li>Steady-state heat transfer.</li>
              <li>Heat transfer is radial in one-dimensional.</li>
              <li>The temperature of the surrounding fluid (or ambient) is constant.</li>

            </ol>

            <h3>Governing Equation:</h3>
            <p>For a cylindrical surface with insulation, the heat transfer rate 𝑄 is given by Fourier’s law of heat conduction - </p>
            <div class="equations" ref='mathContainer1'></div>
            <p>For a cylinder, the surface area A is given by:</p>
            <div class="equations" ref='mathContainer2'></div>
            <div class="diagram">
              <img src="../../assets/images/heat_transfer_img/critical_radii_of_insulation.png" alt="Critical Radius of Insulation">
            </div>
            <p>After integrating:</p>
            <div class="equations" ref='mathContainer3'></div>
            <p>where:</p>
            <ol>
              <li><div ref='mathContainer7'></div></li>
              <li><div ref='mathContainer8'></div></li>
              <li><div ref='mathContainer9'></div></li>
              <li><div ref='mathContainer10'></div></li>
              <li><div ref='mathContainer11'></div></li>
              <li><div ref='mathContainer12'></div></li>
              <li><div ref='mathContainer13'></div></li>
              <li><div ref='mathContainer14'></div></li>
            </ol>
            <p>In the above given equation, pipe resistance could be neglected as pipe have very little resistance in comparision to insulator. So</p>
            <div class="equations" ref="mathContainer4"></div>
            <p>Final Equation :</p>
            <div class="equations" ref="mathContainer5"></div>
            <p>For obtaining critical radii, put  d𝑄/dr = 0, which leads to </p>
            <div class="equations" ref="mathContainer6"></div>
            <div class="diagram">
              <img src="../../assets/images/heat_transfer_img/critical_thickness_of_insulation.png" alt="Critical Thickness of Insulation">
            </div>

          </section>


        
        
  
        </div>
      </div>
  
      
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';
import { onMounted, ref } from 'vue';
import katex from 'katex';
import 'katex/dist/katex.min.css';

export default {
  name: 'ModesofHeatTransfer',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    const mathContainer1 = ref(null);
    const mathContainer2 = ref(null);
    const mathContainer3 = ref(null);
    const mathContainer4 = ref(null);
    const mathContainer5 = ref(null);
    const mathContainer6 = ref(null);
    const mathContainer7 = ref(null);
    const mathContainer8 = ref(null);
    const mathContainer9 = ref(null);
    const mathContainer10 = ref(null);
    const mathContainer11 = ref(null);
    const mathContainer12 = ref(null);
    const mathContainer13 = ref(null);
    const mathContainer14 = ref(null);


    const conductionEquation = 'd\\dot{q} = -k A \\frac{dT}{dr}'; // Fourier's law
    const surfaceArea = 'A = 2 \\pi R L'; // Surface Area of a Cylinder
    const derivedEquation = '\\LARGE \\dot{Q} = \\frac{(T_i - T_o)}{\\frac{\\ln\\left(\\frac{r_o}{r_i}\\right)}{2 \\pi k_p L} + {\\frac{\\ln\\left(\\frac{r}{r_o}\\right)}{2 \\pi k_i L}} + {\\frac{1}{h_{air} 2 \\pi r L}}}'; 
    const piperesistance = '\\LARGE {\\frac{\\ln\\left(\\frac{r_o}{r_i}\\right)}{2 \\pi k_p L}} \\approx 0';
    const finalEquation= '\\LARGE \\dot{Q} = \\frac{ 2 \\pi L (T_i - T_o)}{\\frac{\\ln\\left(\\frac{r}{r_o}\\right)}{k_i} + {\\frac{1}{h_{air} r}}}';
    const criticalRadii = '\\LARGE r_{critical} = \\frac{k_i}{h_{air}}';
    const thermalconductivityofpipesymbol = '\\small {k_p = thermal \\ conductivity \\ of \\ pipe \\ (W/m·K)}';
    const thermalconductivityofinsulatorsymbol = '\\small {k_i = thermal \\ conductivity \\ of \\ insulation \\ (W/m·K)}';
    const ri = '\\small {r_i = inner \\ radius \\ of \\ pipe \\ (m)}';
    const ro = '\\small {r_o = outer \\ radius \\ of \\ pipe \\ (m)}';
    const r = '\\small {r = outer \\ radius \\ of \\ insulation \\ (m)}';
    const Ti = '\\small {T_i = inner \\ temperature \\ (K)}';
    const To = '\\small {T_o = outer \\ temperature \\ (K)}';
    const hr = '\\small {h_{air} = heat \\ transfer \\ coefficient \\ of \\ air \\ (W/m^2·K)}';

    onMounted(() => {
      if (mathContainer1.value) {
        katex.render(conductionEquation, mathContainer1.value, {
          throwOnError: false
        });
      }
      if (mathContainer2.value) {
        katex.render(surfaceArea, mathContainer2.value, {
          throwOnError: false
        });
      }
      if (mathContainer3.value) {
        katex.render(derivedEquation, mathContainer3.value, {
          throwOnError: false
        });
      }
      if (mathContainer4.value) {
        katex.render(piperesistance, mathContainer4.value, {
          throwOnError: false
        });
      }
      if (mathContainer5.value) {
        katex.render(finalEquation, mathContainer5.value, {
          throwOnError: false
        });
      }
      if (mathContainer6.value) {
        katex.render(criticalRadii, mathContainer6.value, {
          throwOnError: false
        });
      }
      if (mathContainer7.value) {
        katex.render(thermalconductivityofpipesymbol, mathContainer7.value, {
          throwOnError: false
        });
      }
      if (mathContainer8.value) {
        katex.render(thermalconductivityofinsulatorsymbol, mathContainer8.value, {
          throwOnError: false
        });
      }
      if (mathContainer9.value) {
        katex.render(ri, mathContainer9.value, {
          throwOnError: false
        });
      }
      if (mathContainer10.value) {
        katex.render(ro, mathContainer10.value, {
          throwOnError: false
        });
      }
      if (mathContainer11.value) {
        katex.render(r, mathContainer11.value, {
          throwOnError: false
        });
      }
      if (mathContainer12.value) {
        katex.render(Ti, mathContainer12.value, {
          throwOnError: false
        });
      }
      if (mathContainer13.value) {
        katex.render(To, mathContainer13.value, {
          throwOnError: false
        });
      }
      if (mathContainer14.value) {
        katex.render(hr, mathContainer14.value, {
          throwOnError: false
        });
      }
    });
    
    useHead({
      title: 'Critical Thickness of Insulation | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'The critical thickness of insulation refers to the thickness of insulation at which the heat loss from a surface is minimized. Beyond this critical thickness, any further increase in insulation thickness results in an increase in heat loss.',
        },
        {
          name: 'keywords',
          content: 'CCritical Thickness of Insulation, Critical Radii, Heat Transfer, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'Critical Thickness of Insulation | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'The critical thickness of insulation refers to the thickness of insulation at which the heat loss from a surface is minimized. Beyond this critical thickness, any further increase in insulation thickness results in an increase in heat loss.',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://themechanicalgenuis.com/heat-transfer/critical-thickness-of-insulation',  // Canonical URL for the homepage
        }
      ]
    });

    return{
      mathContainer1,
      mathContainer2,
      mathContainer3,
      mathContainer4,
      mathContainer5,
      mathContainer6,
      mathContainer7,
      mathContainer8,
      mathContainer9,
      mathContainer10,
      mathContainer11,
      mathContainer12,
      mathContainer13,
      mathContainer14
    }
  },
}
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    
    
    li, p, ul {
      font-size: 1em;
      line-height: 1.75;
      letter-spacing: 0.025em;
      font-family: serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    .equations{
      text-align: center;
      padding-top: 1em;
      padding-bottom: 1em;
    }

    .content-container h3{
      font-size: 1.1em;
      color: #000d47;
      font-weight: 200;
      margin: 0;
    }

    .diagram{
      padding-top: 2em;
      padding-bottom: 2em;
    }


    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .content-container ol{
      padding-inline-start: 1em;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }

    .diagram img{
      width: 100%;
    }


  }
    </style>
    