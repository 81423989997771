<template>
    <header>
      <div :class="['header_container', { 'hide-header': isHidden }]">
        <section class="header_group">
          <div class="mobile-nav-icon" @click="toggleMobileMenu">
            <img src="../assets/hamburger.png" alt="Menu" height="25px"/>
          </div>
          <nav class="flex desktop-nav">
            
  
            <div class="header_topics">
              <ul class="flex">
                <li class="flex">
                  <router-link to="/thermodynamics">
                    <span>Thermodynamics</span>
                  </router-link>
                </li>
                <li class="flex">
                  <router-link to="/fluid-mechanics">
                    <span>Fluid Mechanics</span>
                  </router-link>
                </li>
                <li class="flex">
                  <router-link to="/ic-engine">
                    <span>IC Engine</span>
                  </router-link>
                </li>
                <li class="flex">
                  <router-link to="/">
                    <span>Home</span>
                  </router-link>
                </li>
                <li class="flex">
                  <router-link to="/heat-transfer">
                    <span>Heat Transfer</span>
                  </router-link>
                </li>
                <li class="flex">
                  <router-link to="/machine-design">
                    <span>Machine Design</span>
                  </router-link>
                </li>
                <li class="flex">
                  <router-link to="/others">
                    <span>Topics</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </nav>

          <div class="header_tab"></div>

          <!-- Mobile dropdown menu -->
          <nav class="mobile-nav" v-if="isMobileMenuOpen">
            <ul>
              <li>
                <router-link to="/thermodynamics">
                  <span>Thermodynamics</span>
                </router-link>
              </li>
              <li>
                <router-link to="/fluid-mechanics">
                  <span>Fluid Mechanics</span>
                </router-link>
              </li>
              <li>
                <router-link to="/ic-engine">
                  <span>IC Engine</span>
                </router-link>
              </li>
              <li>
                <router-link to="/">
                  <span>Home</span>
                </router-link>
              </li>
              <li>
                <router-link to="/heat-transfer">
                  <span>Heat Transfer</span>
                </router-link>
              </li>
              <li>
                <router-link to="/machine-design">
                  <span>Machine Design</span>
                </router-link>
              </li>
              <li>
                <router-link to="/others">
                  <span>Topics</span>
                </router-link>
              </li>
            </ul>
          </nav>
          
        </section>
      </div>
    </header>
  </template>
  
  <style>
  .header_container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.5s ease-in-out;
    z-index: 1000;
  }
  
  .hide-header {
    transform: translateY(-100%);
  }
  
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .router_link_css{
    display: flex;
    align-items: center;
  }

  
  .header_topics {
    margin-left: 1em;
  }
  
  .header_topics ul {
    padding: 0;
    column-gap: 4em;
  }
  
  .header_topics span {
    color: #000;
    font-family: Pangram, Avenir, Helvetica, sans-serif;
    font-size: 1em;
    font-weight: 600;
    transition: color 0.3s ease-in-out;
  }
  
  .header_topics span:hover {
    color: #309c99;
  }

  /* Mobile navigation */
.mobile-nav-icon {
  display: none;
  cursor: pointer;
}

.mobile-nav {
  display: none;
}

@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .mobile-nav-icon {
    display: block;
  }

  .header_container{
    width: 100%;
    height: 60px;
  }

  .mobile-nav {
    display: block;
    background-color: #fff;
    position: absolute;
    top: 75px;
    left: 0;
    right: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .mobile-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .mobile-nav li {
    border-bottom: 1px solid #ddd;
  }

  .mobile-nav li a {
    display: block;
    padding: 1em;
    color: #000;
    text-decoration: none;
    font-family: Pangram, Avenir, Helvetica, sans-serif;
    font-weight: 600;
  }

  .mobile-nav li a:hover {
    background-color: #f3f2f285;
  }
}
</style>
  
  <script>
  export default {
    name: 'Header_bar',
    data() {
      return {
        lastScrollY: 0,
        isHidden: false,
        isMobileMenuOpen: false
      };
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeMount() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll() {
        if (window.scrollY > this.lastScrollY) {
          this.isHidden = true;
        } else {
          this.isHidden = false;
        }
        this.lastScrollY = window.scrollY;
      },
      toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    },
  };
  </script>
  