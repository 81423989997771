<template>
    <teleport to="head">
      <component :is="'script'" type="application/ld+json">
        {{
          JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Article",
                "@id": `${link}-article`,
                "headline": headline,
                "description": description,
                "author": {
                  "@type": "Organization",
                  "name": "The Mechanical Genius",
                  "url": "https://themechanicalgenuis.com"
                },
                "publisher": {
                  "@type": "Organization",
                  "name": "The Mechanical Genius"
                },
                "keywords": keywords,
                "inLanguage": "en-US"
              },
              {
                "@type": "BreadcrumbList",
                "@id": `${link}-breadcrumb`,
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": topic,
                    "item": `${topiclink}`
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": headline,
                    "item": `${link}`
                  }
                ]
              },
              {
                "@type": "WebSite",
                "url": "https://themechanicalgenuis.com/",
                "name": "The Mechanical Genius",
                "alternateName": "TMG",
                "description": "The Mechanical Genius offers a complete guide to mechanical engineering, from basic concepts to advanced topics. Explore detailed diagrams, step-by-step solutions, and clear definitions to ace your semester exams or refresh your fundamentals.",
                "publisher": {
                  "@type": "Organization",
                  "@id": "https://themechanicalgenuis.com/"
                },
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": {
                      "@type": "EntryPoint",
                      "urlTemplate": "/?s={search_term_string}"
                    },
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "Quiz",
                "about": {
                  "@type": "Thing",
                  "name": topic
                },
                "educationalAlignment": [
                  {
                    "@type": "AlignmentObject",
                    "alignmentType": "educationalSubject",
                    "targetName": "Mechanical Engineering"
                  }
                ],
                "hasPart": [
                  {
                    "@type": "Question",
                    "eduQuestionType": "Flashcard",
                    "text": question,
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": answer
                    }
                  }
                ]
              }
            ]
          })
        }}
      </component>
    </teleport>
  </template>
  
  <script>
  export default {
    props: {
      link: {
        type: String,
        required: true
      },
      headline: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      keywords: {
        type: Array,
        required: true
      },
      topic: {
        type: String,
        required: true
      },
      topiclink: {
        type: String,
        required: true
      },
      question: {
        type: String,
        required: true
      },
      answer: {
        type: String,
        required: true
      }
    }
  };
  </script>
  