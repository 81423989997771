<template>
    <Header_bar />
    <teleport to="head">
        <component :is="'script'" type="application/ld+json">
          {
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": "What is Scavenging? Types of Scavenging Process and its importance | The Mechanical Genius",
            "description": "Scavenging is the process of removing exhaust gases from the combustion chamber.",
            "author": {
              "@type": "Organization",
              "name": "The Mechanical Genius"
            },
            "publisher": {
              "@type": "Organization",
              "name": "The Mechanical Genius"
            },
            "url": "https://themechanicalgenuis.com/ic-engine/scavenging",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://themechanicalgenuis.com"
            }
          }
        </component>
      </teleport>
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>IC Engine</h3>
              </div>
  
  
              <div class="article-title">
                <h1>What is Scavenging? What are different types of Scavenging Process and describe its importance</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
          <section class="content-container">
            <h2>Definition</h2>
            <p>Scavenging is the process of removing exhaust gases from the combustion chamber of an engine and replacing them with a fresh air-fuel mixture (in the case of a spark-ignition engine) or fresh air (in the case of a compression-ignition engine). This process is crucial for maintaining engine efficiency, power output, and emission control.</p>
          </section>
  
          <section class="content-container">
            <h2>Factors affecting Scavenging Process</h2>
            <ol>
              <li><strong>Engine Design</strong></li><p>The design and configuration of the intake and exhaust ports, as well as the shape of the combustion chamber, play a crucial role in the efficiency of the scavenging process.</p>
              <li><strong>Timing and Size of Port: </strong></li><p>The timing and size of the intake and exhaust ports or valves determine how effectively the fresh charge can enter and the exhaust gases can exit the cylinder.</p>
              <li><strong>Boost Pressure</strong></li><p>In forced induction engines (turbocharged or supercharged), the increased pressure of the intake charge can enhance the scavenging process by more effectively pushing out exhaust gases.</p>
              <li><strong>Scavenging Pattern</strong></li><p>The specific type of scavenging (cross-flow, loop, or uniflow) and the associated flow patterns can influence the effectiveness of the process.</p>
            </ol>
          </section>
        
        <section class="content-container">
          <h2>Types of Scavenging Process</h2>
          <ol>
            <li><strong>Cross-Flow Scavenging</strong></li>
            <p>In cross-flow scavenging, the intake and exhaust ports are positioned on opposite sides of the cylinder. As the fresh charge enters through the intake port, it pushes the exhaust gases out through the exhaust port in a cross-flow pattern.</p>
            <img src="../../assets/images/ic_engine_img/crossflow_scavenging.png" alt="Cross-Flow Scavenging">

            <li><strong>Loop Scavenging</strong></li>
            <p>In loop scavenging, both the intake and exhaust ports are located on the same side of the cylinder. The fresh charge enters the cylinder, flows upward, loops over, and pushes the exhaust gases out through the exhaust port.</p>
            <img src="../../assets/images/ic_engine_img/loop_scavenging.png" alt="Loop Scavenging">
            
            <li><strong>Uniflow Scavenging</strong></li>
            <p>In uniflow scavenging, the fresh charge enters the cylinder from one end (usually through intake ports at the bottom of the cylinder) and pushes the exhaust gases out through an exhaust valve or port located at the opposite end. This type of scavenging is common in larger, more powerful engines.</p>
            <img src="../../assets/images/ic_engine_img/uniflow_scavenging.png" alt="Uni-Flow Scavenging" width="200em">
            </ol>
          
        </section>
  
        </div>
      </div>
  
      
</template>
    
<script>
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'Detonation_',
  components:{
    Header_bar
  },
  setup() {
    useHead({
      title: 'What is Scavenging? Types of Scavenging Process and its importance | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'Scavenging is the process of removing exhaust gases from the combustion chamber.',
        },
        {
          name: 'keywords',
          content: 'Scavenging, Types of Scavenging Process, Factors determining Scavenging, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'What is Scavenging? Types of Scavenging Process and its importance | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'Scavenging is the process of removing exhaust gases from the combustion chamber.',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://themechanicalgenuis.com/ic-engine/scavenging',  // Canonical URL for the homepage
        }
      ]
    });
  },
}
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    
    
    li, p, ul {
      font-size: 1.1em;
      line-height: 1.75;
      text-align: justify;
      letter-spacing: 0.025em;
      font-family: serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 22em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .diagram img{
      width: 100%;
    }

    li, p, ul{
      text-align: left;
    } 

    .content-container ol{
      padding-inline-start: 1em;
    }

    .content-container img{
      width: 100%;
    }

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }


    }
    </style>
    