<template>
    <router-view/>
</template>

<script>
export default {
  name: 'App',
};
</script>


<style>
body {
  margin: 0;
  font-family: Merriweather, Georgia, serif;
}
</style>
