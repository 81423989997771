<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/thermodynamics/second-law-of-thermodynamics'"
      :headline="'What is Second Law of Thermodynamics | The Mechanical Genius'"
      :description="'The Second Law of Thermodynamics states that total entropy of universe never decreases over time.'"
      :keywords="['Second Law of Thermodynamics', 'Laws of Thermodynamics', 'Mechanical Engineering']"
      :topic="'Thermodynamics'"
      :topiclink="'https://themechanicalgenuis.com/thermodynamics'"
      :question="'What is Second Law of Thermodynamics?'"
      :answer="'The Second Law of Thermodynamics states that total entropy of universe never decreases over time. It implies that natural processes tend to move towards a state of greater disorder or entropy.'"
    />
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Thermodynamics</h3>
              </div>
  
  
              <div class="article-title">
                <h1>What is Second Law of Thermodynamics?</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
          <section class="content-container">
            <h2>Definition</h2>
            <p>The Second Law of Thermodynamics states that total entropy of universe never decreases over time. It implies that natural processes tend to move towards a state of greater disorder or entropy.</p>
          </section>
  
          <section class="content-container">
          <h2>Equation</h2>
          <p>The mathematical expression for the Second Law of Thermodynamics is:</p>
          <p><strong>ΔS<sub>universe</sub><span> &#8805; </span> 0</strong></p>
          <p>where:</p>
          <ul>
            <li><strong>ΔS</strong> = Change in entropy of the universe</li>
          </ul>
        </section>

        <section class="content-container">
          <h2>Kelvin-Plank Statement</h2>
          <p>The Kelvin-Planck statement of the second law of thermodynamics states that it is impossible to devise a heat engine that takes heat from the hot reservoir (Q<sub>H</sub>) and converts all the energy into useful external work without losing heat to the cold reservoir (Q<sub>C</sub>).</p>
          <div class="diagram">
            <img src="../../assets/images/thermodynamics_img/second_law_of_thermodynamics_1.png" alt="Kelvin-Plank Statement"/>
          </div>
          
        </section>

        <section class="content-container">
          <h2>Clausius Statement</h2>
          <p>The Clausius statement of the second law of thermodynamics states that it is impossible to construct a device which operates on a cycle and whose sole effect is the transfer of heat from a cooler body to a hotter body.</p>
          <div class="diagram">
            <img src="../../assets/images/thermodynamics_img/second_law_of_thermodynamics_1.png" alt="Kelvin-Plank Statement"/>
          </div>
          
        </section>
        
        <section class="content-container">
          <h2>Example</h2>
          <p>Consider a refrigerator, which operates on the principle of the second law of thermodynamics. A refrigerator transfers heat from the cold interior (cold reservoir) to the warmer environment (hot reservoir) outside. According to the Clausius statement, this transfer of heat from a colder to a hotter body is not spontaneous and requires external work, which is supplied by the refrigerator's compressor.</p>
          <p>In terms of the Kelvin-Planck statement, the refrigerator as a heat engine cannot convert all the electrical work (input energy) into the cooling effect (useful work). There is always some waste heat released into the surroundings.</p>
        </section>
  
        </div>
      </div>
  
      
    </template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'SecondLawOfThermodynamics',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    useHead({
      title: 'What is Second Law of Thermodynamics | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'The Second Law of Thermodynamics states that total entropy of universe never decreases over time.',
        },
        {
          name: 'keywords',
          content: 'Second Law of Thermodynamics, Laws of Thermodynamics, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'What is Second Law of Thermodynamics | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'The Second Law of Thermodynamics states that total entropy of universe never decreases over time',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });
  },
};
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }

     .diagram{
      padding: 2em;
    }
    
    
    
    p, ul {
      font-size: 1.1em;
      line-height: 1.75;
      text-align: justify;
      letter-spacing: 0.025em;
      font-family: Merriweather, Georgia, serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .diagram img{
      width: 100%;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }

  }
    </style>
    