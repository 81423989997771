<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/machine-design/stress-concentration'"
      :headline="'What is Stress Concentration | The Mechanical Genius'"
      :description="'Stress concentration is a localized zone in a structure at which the stress is significantly higher than in the surrounding material'"
      :keywords="['Stress Concentration', 'Machine Design', 'Mechanical Engineering']"
      :topic="'Machine Design'"
      :topiclink="'https://themechanicalgenuis.com/machine-design'"
      :question="'What is stress concentration? How to mitigate stress concentration?'"
      :answer="'Stress concentration is a localized zone in a structure at which the stress is significantly higher than in the surrounding material. It occur when there are irregularities in the geometry such as notches, holes, or sharp corners that cause an interruption to the flow of stress.'"
    />
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Machine Design</h3>
              </div>
  
  
              <div class="article-title">
                <h1>What is stress concentration? How to mitigate stress concentration?</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
          <section class="content-container">
            <h2>Definition</h2>
            <p>Stress concentration is a localized zone in a structure at which the stress is significantly higher than in the surrounding material. It occur when there are irregularities in the geometry such as notches, holes, or sharp corners that cause an interruption to the flow of stress. Degree of concentration is expressed as stress concentration factor K<sub>t</sub>, which is ratio of maximum stress at the point of interest to the nominal stress applied to the component. It is mathematically expressed as:</p>
            <div class="equations" ref="mathContainer1"></div>
            <div class="diagram">
                <img src="../../assets/images/machine_design_img/stress_conc.png" alt="Stress Concentration">
            </div>
          </section>
  
          <section class="content-container">
            <h2>Steps to Mitigate Stress Concentration</h2>
            <ol>
              <li>
                <strong>Smooth Transitions: </strong>
                <p>Use gradual changes in geometry (e.g., fillets or chamfers) instead of sharp corners or abrupt changes. This reduces stress concentrations by distributing the load more evenly.</p>
              </li>
              <li>
                <strong>Increase Cross-Sectional Area: </strong>
                <p>Design components with a larger cross-sectional area where high-stress concentrations are anticipated, as this helps distribute stress over a larger area.</p>
              </li>
              <li>
                <strong>Add Reinforcements: </strong>
                <p>Incorporate reinforcements (like ribs or stiffeners) in high-stress areas to distribute loads more evenly.</p>
              </li>
              <li>
                <strong>Finite Element Analysis (FEA): </strong>
                <p>Use FEA during the design phase to identify and analyze stress concentration areas, allowing for design modifications before production.</p>
              </li>
              <li>
                <strong>Material Selection: </strong>
                <p>Choose materials with higher ductility and toughness to withstand localized stresses without failing. Ductile materials can deform to relieve stress concentration.</p>
              </li>
            </ol>
          </section>
        
        
  
        </div>
      </div>
  
      
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';
import { onMounted, ref } from 'vue';
import katex from 'katex';
import 'katex/dist/katex.min.css';

export default {
  name: 'StressConcentration',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    const mathContainer1 = ref(null);
    const StressConcentrationFactor = '\\Large K_t = \\frac{\\sigma_{max}}{\\sigma_{nom}}';
    
    onMounted(() => {
      if (mathContainer1.value) {
        katex.render(StressConcentrationFactor, mathContainer1.value, {
          throwOnError: false
        });
      }
    });

    useHead({
      title: 'What is Stress Concentration | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'Stress concentration is a localized zone in a structure at which the stress is significantly higher than in the surrounding material',
        },
        {
          name: 'keywords',
          content: 'Stress Concentration, Mitigation of stress concentration, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'What is Stress Concentration | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'Stress concentration is a localized zone in a structure at which the stress is significantly higher than in the surrounding material',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });
    return{
      mathContainer1
    }
  },
}
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    
    
     li, p, ul {
      font-size: 1em;
      line-height: 1.75;
      letter-spacing: 0.025em;
      font-family: serif;
      margin-top: 0;
     
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    .diagram{
      padding-top: 2em;
      padding-bottom: 2em;
    }

    .equations{
      text-align: center;
    }

    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .content-container ol{
      padding-inline-start: 1em;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }

    .diagram img{
      width: 100%;
    }


  }
    </style>
    