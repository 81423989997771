<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/heat-transfer/thermal-conductivity'"
      :headline="'What is Thermal Conductivity? How thermal conductivity varies for solid, liquid and gases | The Mechanical Genius'"
      :description="'The rate of heat transfer through a unit thickness of the material per unit area per unit temperature difference.'"
      :keywords="['Thermal Conductivity', 'Heat Transfer', 'Mechanical Engineering']"
      :topic="'Heat Transfer'"
      :topiclink="'https://themechanicalgenuis.com/heat-transfer'"
      :question="'What is Thermal Conductivity? How thermal conductivity varies for solid, liquid and gases?'"
      :answer="'Thermal Conductivity is defined as the rate of heat transfer through a unit thickness of the material per unit area per unit temperature difference.'"
    />
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Heat Transfer</h3>
              </div>
  
  
              <div class="article-title">
                <h1>What is Thermal Conductivity? How thermal conductivity varies for solid, liquid and gases?</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
          <section class="content-container">
            <h2>Definition</h2>
            <p>The rate of heat transfer through a unit thickness of the material per unit area per unit temperature difference.</p>
            <p>OR</p>
            <p>The thermal conductivity is a measure of the ability of the material to conduct heat.</p>  
                   
          </section>
  
          <section class="content-container">
            <h2>Variation of Thermal Conductivity</h2>
            <div ref='mathContainer2'></div>
            <ol>
              <li>
                <h3>Solids</h3>
                <p>Most solids, especially metals, have high thermal conductivity due to the presence of closely packed atoms. The primary mode of heat transfer is through conduction, facilitated by free electrons (in metals) or vibrations of atoms in a lattice (in non-metals).</p>
                <p><strong>Variation with Temperature:</strong> Thermal conductivity in metals typically decreases with increasing temperature, while in non-metals, it increases.</p>
              </li>
              <li>
                <h3>Liquids</h3>
                <p>The thermal conductivity of liquids is lower than that of solids but higher than gases. In liquids, heat transfer occurs mainly through conduction and convection.</p>
                <p><strong>Variation with Temperature:</strong> The thermal conductivity of liquids usually increases with temperature as molecular motion increases, enhancing the transfer of kinetic energy.</p>
              </li>
              <li>
                <h3>Gases</h3>
                <p>Gases have the lowest thermal conductivity among the three states of matter. The molecules in gases are far apart, and heat transfer occurs primarily through collisions between molecules, which is less efficient than in solids and liquids.</p>
                <p><strong>Variation with Temperature:</strong> The thermal conductivity of gases also increases with temperature due to the increased kinetic energy and speed of molecular motion.</p>
              </li>
              </ol>
            
          </section>
        
        </div>
      </div>
  
      
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';
import { onMounted, ref } from 'vue';
import katex from 'katex';
import 'katex/dist/katex.min.css';

export default {
  name: 'ModesofHeatTransfer',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    const mathContainer1 = ref(null);
    const mathContainer2 = ref(null);
    const thermalconductivitysymbol = 'k'; 
    const comparision = 'Solid > Liquid > Gas'

    onMounted(() => {
      if (mathContainer1.value) {
        katex.render(thermalconductivitysymbol, mathContainer1.value, {
          throwOnError: false
        });
      }
      if (mathContainer2.value) {
        katex.render(comparision, mathContainer2.value, {
          throwOnError: false
        });
      }
    });

    useHead({
      title: 'What is Thermal Conductivity? How thermal conductivity varies for solid, liquid and gases | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'The rate of heat transfer through a unit thickness of the material per unit area per unit temperature difference.',
        },
        {
          name: 'keywords',
          content: 'Conduction, Convection , Radiation, Heat Transfer, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'What is Thermal Conductivity? How thermal conductivity varies for solid, liquid and gases | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'The rate of heat transfer through a unit thickness of the material per unit area per unit temperature difference.',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });

    return {
      mathContainer1,
      mathContainer2
    };
  },

}
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    
    
    li, p, ul {
      font-size: 1em;
      line-height: 1.75;
      letter-spacing: 0.025em;
      font-family: serif;
      margin-top: 0;
     
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    .content-container h3{
      font-size: 1.1em;
      color: #000d47;
      font-weight: 200;
      margin: 0;
    }

    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .content-container ol{
      padding-inline-start: 1em;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }


  }
    </style>
    