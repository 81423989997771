// List of Topics in section Heat Transfer

export default [
    { id: 'heat-transfer/modes-of-heat-transfer', name: 'What are the different modes of Heat Transfer?'},
    { id: 'heat-transfer/critical-thickness-of-insulation', name: 'What is critical thickness of Insulation?'},
    { id: 'heat-transfer/thermal-conductivity', name: 'What is Thermal Conductivity? How thermal conductivity varies for solid, liquid and gases?'},
    { id: 'heat-transfer/effect-of-temperature-on-thermal-conductivity', name: 'How does temperature affect Thermal Conductivity?'},
    { id: '', name: 'Let us know other topics you want included in Heat Transfer'},
    
    // Add more topics here
  ];