// List of Topics in section Fluid Mechanics

export default [
    { id: 'fluid-mechanics/pelton-vs-francis-turbine', name: 'Difference between Pelton and Francis Turbine'},
    { id: 'fluid-mechanics/impulse-vs-reaction-turbine', name: 'Difference between Impulse and Reaction Turbine'},
    { id: 'fluid-mechanics/pelton-vs-francis-vs-kaplan-turbine', name: 'Difference between Pelton, Francis and Kaplan Turbine'},
    { id: 'fluid-mechanics/advantage-drawback-francis-over-pelton', name: 'Advantages and Disadvantages of Francis over Pelton Turbine'},
    { id: 'fluid-mechanics/pelton-wheel-derivation', name: 'Work done and Efficiency derivation of Pelton Turbine'},
    { id: 'fluid-mechanics/max-hydraulic-efficiency-pelton-wheel', name: 'Maximum hydraulic efficiency derivation for pelton turbine'},
    { id: '', name: 'Let us know other topics you want included in Fluid Mechanics'},
    // Add more topics here
  ];