<template>
  
    <div class="home">
      <Header_bar />
      <teleport to="head">
        <component :is="'script'" type="application/ld+json">
          {
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": "Machine Design | The Mechanical Genius",
            "description": "Machine design is the process of designing machine parts, systems or mechanisms, that will yield desired outputs, using principles and laws of engineering.",
            "author": {
              "@type": "Organization",
              "name": "The Mechanical Genius"
            },
            "publisher": {
              "@type": "Organization",
              "name": "The Mechanical Genius"
            },
            "url": "https://themechanicalgenuis.com/machine-design",
            "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://themechanicalgenuis.com"
            }
          }
        </component>
      </teleport>
      <section class="featured">
        <div class="featured-headline">
          <h1>Machine Design</h1>
        </div>
      </section>

      <section class="topics">
        <ul class="topics-m">
          <li v-for="topic in machine_design_topics" :key="topic.id">
            <router-link :to="`/${topic.id}`">
              <div class="topic-container">
                <h2>{{ topic.name }}</h2>
              </div>
          </router-link>
          </li>
        </ul>
      </section>
  
      
    
    </div>
  </template>
  
  <script>
  import Header_bar from '@/layouts/header.vue';
  import machine_design_topics from '../../data/Machine_Design';
  import { useHead } from '@vueuse/head'
  export default {
    name: 'MachineDesignPage',
    components: {
      Header_bar,
    },
    data(){
      return {
        machine_design_topics: machine_design_topics
      };
    
    },
    setup() {
      useHead({
          title: 'Machine Design | The Mechanical Genius',
          meta: [
            {
              name: 'description',
              content: 'Machine design is the process of designing machine parts, systems or mechanisms, that will yield desired outputs, using principles and laws of engineering.',
            },
            {
              name: 'keywords',
              content: 'Machine Design, Mechanical Engineering',
            },
            {
              property: 'og:title',
              content: 'Machine Design | The Mechanical Genius',
            },
            {
              property: 'og:description',
              content: 'Machine design is the process of designing machine parts, systems or mechanisms, that will yield desired outputs, using principles and laws of engineering.',
            },
            {
              property: 'og:type',
              content: 'article',
            }
          ],
        });
      },
  };
 
  </script>
  
  <style scoped>

  .home {
    font-family: 'Arial, sans-serif';
    color: #333;
  }

  .featured {
    padding-top: 6em;
    padding-bottom: 4em;
    padding-left: 4rem;
    padding-right: 4rem;
    background-color: #f3f2f285;
    text-align: center;
    margin-bottom: 40px;
  }

  .featured-headline{
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
  }

  .featured-headline h1{
    font-size: 3em;
    font-family: serif;
    font-weight: 100;
  }

  .topics{
    padding: 0 5em;
    display: flex;
  }

  .topics-m{
    width: 60%;
    margin: 0;
  }

  .topics-m li{
    list-style: none;
  }

  .topic-container{
    background-color: #f3f2f285;
    padding: 0.5em 2em;
    margin: 0.75em 0;
    border-radius: 1em;
    transition: color 0.3s 0.1s ease-in-out;
  }

  .topic-container h2{
    font-family: serif;
    font-weight: 100;
  }

  .topic-container:hover{
    color: #309c99;
  }

  @media (max-width: 768px) {
    .featured {
      padding-top: 6em;
      padding-bottom: 2em;
      padding-left: 2rem;
      padding-right: 2rem;
      background-color: #f3f2f285;
      text-align: center;
      margin-bottom: 20px;
    }

    .featured-headline h1{
      font-size: 2em;
      font-family: serif;
      font-weight: 100;
    }

    .topics{
      padding: 0 1em;
      display: flex;
    }

    .topics-m{
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .topic-container{
      background-color: #f3f2f285;
      padding: 0.5em 2em;
      margin: 0.75em 0;
      border-radius: 1em;
      transition: color 0.3s 0.1s ease-in-out;
    }

    .topic-container h2{
      font-family: serif;
      font-weight: 100;
      font-size: 1em;
    }

    .topic-container:hover{
      color: #309c99;
    }
    
  }
    
  
  .footer {
    background-color: #f8f9fa;
    text-align: center;
    padding: 20px;
    border-top: 1px solid #ddd;
    margin-top: 40px;
  }
  </style>
  