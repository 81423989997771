<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/machine-design/factor-of-safety'"
      :headline="'What is the factor of safety | The Mechanical Genius'"
      :description="'The factor of safety is the ratio of the maximum stress that a material can withstand to the maximum stress that it is subjected to in a given application.'"
      :keywords="['FOS', 'Factor of Safety', 'Machine Design', 'Mechanical Engineering']"
      :topic="'Machine Design'"
      :topiclink="'https://themechanicalgenuis.com/machine-design'"
      :question="'What is the factor of safety?'"
      :answer="'Factor of Safety (FOS) is defined as ratio of ultimate strength of the component to the working stress. The FoS provides a margin of safety for uncertainty in load estimations, material properties, and potential failure modes.'"
    />
    
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Machine Design</h3>
              </div>
  
  
              <div class="article-title">
                <h1>What is the factor of safety? On what factors does FOS depend upon?</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
          <section class="content-container">
            <h2>Definition</h2>
            <p>Factor of Safety (FOS) is defined as ratio of ultimate strength of the component to the working stress. The FoS provides a margin of safety for uncertainty in load estimations, material properties, and potential failure modes.</p>
            <p>OR</p>
            <p>It is also defined as ratio of strength of material to maximum allowable stress.</p>
            <div ref="mathContainer1"></div>
          </section>
  
          <section class="content-container">
            <h2>Factor of Safety (FOS) depens upon -</h2>
            <ol>
              <li>
                <strong>Properties of the Material: </strong>
                Strength, ductility, and toughness of materials used in the component influence the FoS.
              </li>
              <li>
                <strong>Load Conditions: </strong>
                The nature of the loads (static, dynamic, cyclic, etc.) and their magnitudes affect the FoS. Dynamic loads often require a higher FoS due to potential impact or fatigue.
              </li>
              <li>
                <strong>Design Standards and Codes: </strong>
                Regulatory codes and industry standards specify minimum FoS values for different materials and applications to ensure safety.
              </li>
              <li>
                <strong>Service Conditions: </strong>
                Environmental factors such as temperature, humidity, and corrosive agents can affect the FoS by degrading material properties.
              </li>
              <li>
                <strong>Consequences of Failure: </strong>
                The potential consequences of structural failure (e.g., loss of life, financial loss, or damage to property) may lead to higher FoS requirements in critical applications.
              </li>
            </ol>
          </section>
        
        
  
        </div>
      </div>
  
      
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';
import { onMounted, ref } from 'vue';
import katex from 'katex';
import 'katex/dist/katex.min.css';

export default {
  name: 'FactorOfSafety',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    const mathContainer1 = ref(null);
    const fos = '\\text{Factor of Safety (FOS)} = \\frac{\\text{Ultimate Strength of the component}}{\\text{Working Stress}}';
    
    onMounted(() => {
      if (mathContainer1.value) {
        katex.render(fos, mathContainer1.value, {
          throwOnError: false
        });
      }
    });

    useHead({
      title: 'What is the factor of safety | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'The factor of safety is the ratio of the maximum stress that a material can withstand to the maximum stress that it is subjected to in a given application.',
        },
        {
          name: 'keywords',
          content: 'Factor of safety, FOS, Machine Design Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'What is the factor of safety | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'The factor of safety is the ratio of the maximum stress that a material can withstand to the maximum stress that it is subjected to in a given application.',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://themechanicalgenuis.com/machine-design/factor-of-safety',  // Canonical URL for the homepage
        }
      ]
    });
    return{
      mathContainer1
    }
  },
}
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    
    
    li, p, ul {
      font-size: 1.1em;
      line-height: 1.75;
      text-align: justify;
      letter-spacing: 0.025em;
      font-family: serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .content-container ol{
      padding-inline-start: 1em;
    }

    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }


  }
    </style>
    