<template>
    <div class="home">
      <Header_bar />
      <teleport to="head">
        <component :is="'script'" type="application/ld+json">
          {
            "@context":"https://schema.org",
            "@graph":[
            {
              "@type":"WebSite",
              "url":"https://themechanicalgenuis.com/",
              "name":"The Mechanical Genius",
              "alternateName": ["The Mechanical Engineering Notes", "Mechanical Sem Notes"],
              "description":"The Mechanical Genius offers a complete guide to mechanical engineering, from basic concepts to advanced topics. Explore detailed diagrams, step-by-step solutions, and clear definitions to ace your semester exams or refresh your fundamentals.",
              "publisher":{
                "@type":"Organization",
                "@id":"https://themechanicalgenuis.com/"
              },
              "potentialAction":[
              {
                "@type":"SearchAction",
                "target":{
                  "@type":"EntryPoint",
                  "urlTemplate":"/?s={search_term_string}"
                },
                "query-input":"required name=search_term_string"
              }
              ],
              "inLanguage":"en-US"
            },

            {
              "@type": "Organization",
              "url": "https://themechanicalgenuis.com/",
              "logo": "https://themechanicalgenuis.com/logo.png",
              "name": "The Mechanical Genius",
              "alternateName": ["Mechanical Sem Notes", "Mechanical Engineering Notes", "TMG"],
              "description": "The Mechanical Genius offers a complete guide to mechanical engineering, from basic concepts to advanced topics. Explore detailed diagrams, step-by-step solutions, and clear definitions to ace your semester exams or refresh your fundamentals.",
              "email": "themechanicalgeniuss@gmail.com"

            }]
          }
        </component>
      </teleport>
  
      <main class="main-content">
        <section class="featured">
          
          <div class="featured-headline">
            <h1>The Mechanical Genius</h1>
          </div>
        </section>

        <section class="home-articles desktop-home">
          <div class="featured-articles">
            <section class="tag-1">
              <div v-for="post in home_page_featured" :key="post.id" class="article-container">
                <router-link :to='post.link_to' class="post-router-link-css">
                  <div class="article-image">
                    <img :src="post.image_link" :alt="post.image_title" />
                  </div>
                  <div class="article-content">
                    <div class="article-topic">
                          <h3>{{ post.topic }}</h3>
                    </div>
                    <div class="article-title">
                          <h1>{{ post.name }}</h1>
                    </div>
                    <div class="article-description">
                      <p>{{ post.description }}</p>
                    </div>
                  </div>
              </router-link>

              </div> 
            </section>
          </div>

        </section>


        <section class="mobile-home">
          <div class="mobile-featured-articles">
            <section class="mobile-tag-1">
              <div v-for="post in home_page_featured" :key="post.id" class="mobile-article-container">
                <router-link :to='post.link_to' class="mobile-router-link-css">
                  <div class="mobile-article-image">
                    <img :src="post.image_link" :alt="post.image_title" />
                  </div>
                  <div class="mobile-article-content">
                    <div class="mobile-article-topic">
                          <h3>{{ post.topic }}</h3>
                    </div>
                    <div class="mobile-article-title">
                          <h1>{{ post.name }}</h1>
                    </div>
                    <div class="mobile-article-description">
                      <p>{{ post.description }}</p>
                    </div>
                  </div>
                </router-link>

              </div>
            </section>
          </div>

        </section>

        <section class="message-container">
          <div class="message-box">
            <div class="message-heading">Hi!</div>
            <p class="message-para">Let us know if there are any topics you'd like to see or if you spot any errors.</p>
            
            <div class="form-to-fill">
              <form v-if="!feedbackSent" @submit.prevent="sendFeedback" class="bgwhite">
                <div class="flex-auto">
                  <div class="form-field">
                    <label for="message">Message</label>
                    <input type="text" class="message-input-text" id="message" v-model="message" placeholder="Message">
                  </div>
                  <button type="submit">Submit</button>
                </div>
                
              </form>
              <p v-else class="centering">Thank you for your feedback!</p>
            </div>
          </div>
        </section>


  
        
      </main>

  
      <footer class="footer">
        <p>&copy; 2024 The Mechanical Genius. All rights reserved.</p>
      </footer>
    </div>
  </template>
  
<script>
import emailjs from 'emailjs-com';
import home_page_featured from '../data/home_page_featured.js';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';

  export default {
    name: 'HomePage',
    components: {
      Header_bar,
    },
    data(){
      return {
        home_page_featured: home_page_featured,
        isMobileMenuOpen: false,
        message: '',
        feedbackSent: false,
      };
    
    },
    setup() {
      useHead({
          title: 'The Mechanical Genius',
          meta: [
            {
              name: 'description',
              content: 'The Mechanical Genius offers a complete guide to mechanical engineering, from basic concepts to advanced topics. Explore detailed diagrams, step-by-step solutions, and clear definitions to ace your semester exams or refresh your fundamentals.',
            },
            {
              name: 'keywords',
              content: 'Mechanical Engineering, Thermodynamics, Heat Transfer, Machine Design, Fluid Mechanics, Refrigeration and Air Conditioning, Strength of Materials',
            },
            {
              property: 'og:title',
              content: 'The Mechanical Genius',
            },
            {
              property: 'og:description',
              content: 'The Mechanical Genius offers a complete guide to mechanical engineering, from basic concepts to advanced topics. Explore detailed diagrams, step-by-step solutions, and clear definitions to ace your semester exams or refresh your fundamentals.',
            },
            {
              property: 'og:type',
              content: 'article',
            }
          ],
          link: [
            {
              rel: 'canonical',
              href: 'https://themechanicalgenuis.com/',  // Canonical URL for the homepage
            }
          ]
        });
      },
      methods:{
        toggleMobileMenu() {
          this.isMobileMenuOpen = !this.isMobileMenuOpen;
        },
        sendFeedback() {
          const serviceID = 'service_l9x39w6';
          const templateID = 'template_b0dyx7x';
          const userID = 'bIYZbh3VbDb-SdpdB';

          emailjs.send(serviceID, templateID, {
            message: this.message,
          }, userID)
          .then(() => {
            this.feedbackSent = true;
            this.message = '';
          }, (error) => {
            console.error('Failed to send feedback:', error);
          });
        }
      },
  };
  </script>
  
  <style scoped>

  .home {
    font-family: 'Arial, sans-serif';
    color: #333;
  }
  
  .header {
    background-color: #f8f9fa;
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .title {
    margin: 0;
    font-size: 2em;
    text-align: center;
  }
  
  .nav {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .nav a {
    margin: 0 15px;
    text-decoration: none;
    color: #007bff;
  }
  
  .nav a:hover {
    text-decoration: underline;
  }
  
  
  
  .featured {
    padding-top: 6em;
    padding-bottom: 4em;
    padding-left: 4rem;
    padding-right: 4rem;
    background-color: #f3f2f285;
    text-align: center;
  }

  

  .featured-headline{
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
  }

  .featured-headline h1{
    font-size: 3em;
    font-family: serif;
    font-weight: 100;
  }
    
  .home-articles {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 5em;
    padding-right: 5em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .featured-articles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }

  .tag-1 {
    display: flex;
    flex-direction: column;

  }

  .article-container{
    width: 75%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 2em 0;
    transition: color 0.3s 0.1s ease-in-out;
  }

  .post-router-link-css{
    display: flex;
  }

  .article-image{
    width: 20em;
  }

  .article-image img{
    width: 100%;
    transition: transform 0.3s ease-in-out;
  }

  .article-content{
    width: 50%;
    padding-left: 3em;
    display: flex;
    flex-direction: column;

  }

  .article-topic h3{
    margin: 0;
    font-size: 0.85em;
    color: #309c99;
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 200;
  }

  .article-title h1{
    margin: 0.5em 0;
    font-size: 1.5em;
    font-family: serif;
    font-weight: 100;
  }

  .article-title h1{
    transition: color 0.3s 0.1s ease-in-out;
  }

  .post-router-link-css:hover .article-title h1 {
  color: #309c99;
  }

  .post-router-link-css:hover .article-image img {
  transform: scale(1.05); 
  }

  .article-description p{
    font-size: 0.85em;
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 200;
    text-align: justify;
  }

  .message-container{
    padding: 5em 5em;
    background-color: #efefef;

  }

  .message-box{
    max-width: 600px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .message-heading{
    font-size: 2em;
    font-family: serif;
    font-weight: 100;
    text-align: center;
  }

  .message-para{
    font-size: 0.95em;
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 100;
    margin-bottom: 1em;
    text-align: center;
    color: #6b6b6b;
  }

  .form-to-fill{
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: flex-start;
    height: 60px;
    justify-content: center;
  }

  .bgwhite{
    background-color: white;
  }

  .flex-auto{
    display: flex;
    height: 60px;
  }

  .form-field{
    position: relative;
  }

  form label {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    border: 0;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    word-wrap: normal !important;
  }



  .message-input-text{
    width: 22em;
    padding: 1em 1.5em;
    font-family: serif;
    font-weight: 100;
    font-size: 1.2em;
    border: none;
  }

  input:focus-visible{
    outline: none;
  }

  .form-field::after{
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    background: #ccc;
    height: 1.75em;
    width: 1px;
    margin-top: -.875em;
  }

  form button {
    width: 7em;
    padding: 0.5em 1em;
    font-size: 0.9em;
    background-color: transparent;
    color: #309c99;
    border: none;
    cursor: pointer;
    transition: font-size 0.3s ease-in-out;
  }

  form button:hover {
    font-size: 1em;
  }

  .centering{
    font-family: serif;
    font-weight: 100;
    font-size: 1.2em;
  }


  /* Mobile navigation */
  .mobile-home {
    display: none;
  }

  @media (max-width: 768px) {
    .desktop-home {
      display: none;
    }

    .mobile-home {
      display: flex;
      padding: 0 1em;
      justify-content: flex-start;
      align-items: center;
      
    }

    .featured {
      padding-top: 6em;
      padding-bottom: 2em;
      padding-left: 2em;
      padding-right: 2rem;
      background-color: #f3f2f285;
      text-align: center;
      margin-bottom: 10px;
    }

    .featured-headline h1{
      font-size: 2em;
      font-family: serif;
    }

    .mobile-featured-articles {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    
    }

    

    .mobile-tag-1 {
      display: flex;
      flex-direction: column;

    }

    .mobile-article-container{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 2em 0;
      transition: color 0.3s 0.1s ease-in-out;
    }

    .mobile-router-link-css{
      display: flex;
      flex-direction: column;
    }

    .mobile-article-image{
      width: 100%;
    }

    .mobile-article-image img{
      width: 100%;
      transition: transform 0.3s ease-in-out;
    }

    .mobile-article-content{
      display: flex;
      flex-direction: column;

    }

    .mobile-article-topic h3{
      margin: 0;
      font-size: 0.85em;
      color: #309c99;
      font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
      font-weight: 200;
    }

    .mobile-article-title h1{
      margin: 0.5em 0;
      font-size: 1.5em;
      font-family: serif;
      font-weight: 100;
    }

    .mobile-article-title h1{
      transition: color 0.3s 0.1s ease-in-out;
    }

    .mobile-router-link-css:hover .article-title h1 {
    color: #309c99;
    }

    .mobile-router-link-css:hover .article-image img {
    transform: scale(1.05); 
    }

    .mobile-article-description p{
      font-size: 1em;
      font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
      font-weight: 200;
      
    }
    .message-container{
      padding: 5em 1em;
      background-color: #efefef;

    }

    .message-input-text{
      width: 60%;
    }

    



  }

  .footer {
    background-color: #f8f9fa;
    text-align: center;
    padding: 20px;
    border-top: 1px solid #ddd;
  }
  </style>
  