// List of Topics in section Thermodynamics

export default [
    { id: 'thermodynamics/zeroth-law-of-thermodynamics', name: 'What is Zeroth Law of Thermodynamics?'},
    { id: 'thermodynamics/first-law-of-thermodynamics', name: 'What is First Law of Thermodynamics?'},
    { id: 'thermodynamics/second-law-of-thermodynamics', name: 'What is Second Law of Thermodynamics?'},
    { id: 'thermodynamics/intensive-vs-extensive-properties', name: 'Difference between Intensive and Extensive Properties'},
    { id: 'thermodynamics/quasi-static-process', name: 'What is Quasi Static Process?'},
    { id: '', name: 'Let us know other topics you want included in Thermodynamics'},
    // Add more topics here
  ];