<template>
    <Header_bar />
    <SeoComponent
      :link="'https://themechanicalgenuis.com/fluid-mechanics/advantage-drawback-francis-over-pelton'"
      :headline="'Advantages and Disadvantages of Francis Turbine over Pelton Turbine | The Mechanical Genius'"
      :description="'Advantages and Disadvantages of Francis Turbine over Pelton Turbine'"
      :keywords="['Francis Turbine', 'Pelton Turbine', 'Advantages and Disadvantages', 'Fluid Mechanics', 'Mechanical Engineering']"
      :topic="'Fluid Mechanics'"
      :topiclink="'https://themechanicalgenuis.com/fluid-mechanics'"
      :question="'Advantages and Disadvantages of Francis Turbine over Pelton Turbine'"
      :answer="'In Francis turbine variation in operating head can be more easily controlled. Mechanical efficiency of Pelton turbine decreases faster with wear than Francis turbine.'"
    />
      <div class="article-layout">
  
        <div class="heading-container">
          <div class="header-spacer"></div>
          
            <div class="headline">
              <div class="article-topic">
                <h3>Fluid Mechanics</h3>
              </div>
  
  
              <div class="article-title">
                <h1>Advantages and Disadvantages of Francis Turbine over Pelton Turbine</h1>
              </div>
            </div>
        </div>
  
        <div class="article-content-in-full">
            <section class="content-container">
                <h2>Advantages of Francis Turbine over Pelton Turbine</h2>
                <ol>
                <li>In Francis turbine variation in operating head can be more easily controlled.</li>
                <li>The size of runner, power house and generator required is small and economical if Francis turbine is used instead of pelton turbine for same power generation.</li>
                <li>In Francis turbine the ratio of maximum and minimum head can even be two.</li>
                <li>Mechanical efficiency of Pelton turbine decreases faster with wear than Francis turbine.</li>   
                </ol>
            </section>
        
            <section class="content-container">
                <h2>Disadvantages of Francis Turbine as compared to Pelton Turbine</h2>
                <ol>
                <li>Inspection is much more difficult in Francis turbine comparatively.</li>
                <li>Water hammer effect is much more prominent in Francis turbine.</li>
                <li>Francis turbine has the ever present problem of cavitation.</li>
                </ol>
          
            </section>
  
        </div>
      </div>
  
      
</template>
    
<script>
import SeoComponent from '@/components/ArticleSeo.vue';
import Header_bar from '@/layouts/header.vue';
import { useHead } from '@vueuse/head';

export default {
  name: 'ADFrancisvsPeltonTurbine',
  components:{
    Header_bar,
    SeoComponent
  },
  setup() {
    useHead({
      title: 'Advantages and Disadvantages of Francis Turbine over Pelton Turbine | The Mechanical Genius',
      meta: [
        {
          name: 'description',
          content: 'Advantages and Disadvantages of Francis Turbine over Pelton Turbine.',
        },
        {
          name: 'keywords',
          content: 'Francis Turbine, Pelton Turbine, Advantages and Disadvantages, Fluid Mechanics, Mechanical Engineering',
        },
        {
          property: 'og:title',
          content: 'Advantages and Disadvantages of Francis Turbine over Pelton Turbine | The Mechanical Genius',
        },
        {
          property: 'og:description',
          content: 'Advantages and Disadvantages of Francis Turbine over Pelton Turbine',
        },
        {
          property: 'og:type',
          content: 'article',
        }
      ],
    });
  }
};
</script>
    
    <style scoped>
    .article-layout{
      margin: 0 5em;
    }
  
    .heading-container{
      width: 100%;
      height: 20em;
  
    }
  
    .header-spacer{
      width: 100%;
      height: 10em;
    }
  
    .headline{
      padding: 0 0em;
    }
  
    .content-container{
      padding-bottom: 1em;
    }

    .content-container li{
      display: list-item;
    }
  
    .article-topic h3{
      font-size: 1em;
      color: #309c99;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      font-weight: 200;
  
    }
  
    .article-title h1{
      font-size: 2.5em;
      color: #000;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Helvetica Neue,sans-serif;
      font-weight: 100;
    }
  
    .article-content-in-full{
      width: 75%;
      font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
  
    .article-content-in-full h2{
      font-weight: 200;
      font-size: 1.5em;
      color: #000d47;
      letter-spacing: 0.01em;
     }
    
    
    
    li, p, ul {
      font-size: 1.1em;
      line-height: 1.75;
      text-align: justify;
      letter-spacing: 0.025em;
      font-family: serif;
    }
    
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    .content-container ol li{
      padding-bottom: 1em;
    }

    @media (max-width: 768px) {
    .article-layout{
      padding: 0 1em;
      margin: 0;
    }

    .heading-container{
    width: 100%;
    height: 20em;
    }

    .header-spacer{
      width: 100%;
      height: 5em;
    }

    .article-title h1{
    font-size: 2em;
    color: #000;
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: 100;
    }

    .article-content-in-full{
      width: 100%;
    }

    .content-container ol{
      padding-inline-start: 1em;
    }

    .content-container ol li{
      padding-bottom: 1em;
    }


    li, p, ul{
      text-align: left;
    } 

    p, ul, li {
    font-size: 0.9em;
    line-height: 1.75;
    font-family: serif;
    }


  }
    </style>
    